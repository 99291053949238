import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    questions: null,
    answers: null,
    module_id: null,
    user:null,
    accessTocken:null
  },
  mutations: {
    set_questions(state, { questions, module_id}) {
      state.questions = questions
      state.module_id = module_id
    },
    set_answers(state, { answers}) {
      state.answers = answers
    },
    set_user(state, user) {
      state.user = user
    },
    set_accessTocken(state, accessTocken) {
      state.accessTocken = accessTocken
    }
  },
  actions: {
    set_questions({ commit }, { questions ,module_id}) {
      commit('set_questions', { questions ,module_id})
    },
    set_answers({ commit }, { answers}) {
      commit('set_answers', { answers})
    },
    set_user({ commit }, user) {
      commit('set_user', user)
    },
    set_accessTocken({ commit }, accessTocken) {
      commit('set_accessTocken', accessTocken)
    },
  },
  getters: {
    getQuestions(state) {
      return state.questions
    },
    getUser(state){
      return state.user
    },
    getAccessTocken(state){
      return state.accessTocken
    }
  },
  plugins: [createPersistedState()],
})
