<template>
  <div class="hole-space">
    <div class="container px-4 content">
      <div class="row">
        <div class="col-md-8 mx-auto my-5">
          <h1 class="space-heading text-center">
            Bienvenue dans mon fromage & moi !
          </h1>
          <h3 class="text-center text-compte">
            Créez votre compte pour participer au programme
          </h3>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4 px-md-5 form-group">
          <input
            type="text"
            name=""
            class="form-control"
            id="nom"
            placeholder="Nom"
            :disabled="disableInputs"
            v-model="signup_form.lastName"
            required
          />
          <div
            class="label"
            for="nom"
            :class="{
              changeplace: signup_form.lastName,
              disabled: $route.query.token,
            }"
          >
            Nom
          </div>
          <p class="classError text-danger small">
            {{ validation_form.lastName ? validation_form.lastName[0] : null }}
          </p>
        </div>
        <div class="col-md-4 px-md-5 form-group">
          <input
            type="text"
            name=""
            class="form-control"
            id="prenom"
            placeholder="Prénom"
            v-model="signup_form.firstName"
            :disabled="disableInputs"
            required
          />
          <div
            class="label"
            for="prenom"
            :class="{
              changeplace: signup_form.firstName,
              disabled: $route.query.token,
            }"
          >
            Prénom
          </div>
          <p class="classError text-danger small">
            {{
              validation_form.firstName ? validation_form.firstName[0] : null
            }}
          </p>
        </div>
        <div class="col-md-4 px-md-5 form-group">
          <input
            type="email"
            name=""
            class="form-control"
            placeholder="Email"
            id="email"
            v-model="signup_form.email"
            :disabled="disableInputs"
            required
          />
          <div
            class="label"
            for="email"
            :class="{
              changeplace: signup_form.email,
              disabled: $route.query.token,
            }"
          >
            Email
          </div>
          <p class="classError text-danger small">
            {{ validation_form.email ? validation_form.email[0] : null }}
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4 px-md-5 form-group">
          <input
            type="text"
            name=""
            class="form-control"
            id="phone"
            placeholder="Téléphone"
            v-model="signup_form.phone"
            :error-messages="validation_form.phone"
            required
          />
          <div
            class="label"
            :class="{
              changeplace: signup_form.phone,
              disabled: $route.query.token,
            }"
          >
            Téléphone
          </div>
          <p class="classError text-danger small">
            {{ validation_form.phone ? validation_form.phone[0] : null }}
          </p>
        </div>
        <div class="col-md-4 px-md-5 form-group">
          <input
            type="text"
            name=""
            class="form-control"
            id="zipcode"
            placeholder="Code postale"
            v-model="signup_form.zipcode"
            :error-messages="validation_form.zipcode"
            required
          />
          <div
            class="label"
            :class="{
              changeplace: signup_form.zipcode,
              disabled: $route.query.token,
            }"
          >
            Code postale
          </div>
          <p class="classError text-danger small">
            {{ validation_form.zipcode ? validation_form.zipcode[0] : null }}
          </p>
        </div>
        <div class="col-md-4 px-md-5 form-group">
          <input
            type="text"
            name=""
            class="form-control"
            placeholder="Ville"
            id="ville"
            v-model="signup_form.ville"
            :error-messages="validation_form.ville"
            required
          />
          <div
            class="label"
            :class="{
              changeplace: signup_form.ville,
              disabled: $route.query.token,
            }"
          >
            Ville
          </div>
          <p class="classError text-danger small">
            {{ validation_form.ville ? validation_form.ville[0] : null }}
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4 px-md-5 form-group">
          <input
            type="text"
            name=""
            class="form-control"
            placeholder="Mon école"
            id="ecole_nom"
            v-model="signup_form.school"
            :error-messages="validation_form.school"
            required
          />
          <div class="label" :class="{ changeplace: signup_form.school }">
            Mon école
          </div>
          <p class="classError text-danger small">
            {{ validation_form.school ? validation_form.school[0] : null }}
          </p>
        </div>
        <div class="col-md-4 px-md-5 form-group">
          <input
            type="text"
            name=""
            class="form-control"
            placeholder="Type d'école"
            id="ecole_type"
            v-model="signup_form.school_type"
            :error-messages="validation_form.school_type"
            required
          />
          <div class="label" :class="{ changeplace: signup_form.school_type }">
            Type d'école
          </div>
          <p class="classError text-danger small">
            {{
              validation_form.school_type
                ? validation_form.school_type[0]
                : null
            }}
          </p>
        </div>
        <div class="col-md-4 px-md-5 form-group selection">
          <div class="select-box">
            <div class="options-container">
              <div class="option">
                <input
                  type="radio"
                  class="radio"
                  id="professeur"
                  name="category"
                />
                <label for="professeur">Professeur / enseignant</label>
              </div>

              <div class="option">
                <input
                  type="radio"
                  class="radio"
                  id="formation"
                  name="category"
                />
                <label for="formation">Formation</label>
              </div>

              <div class="option">
                <input
                  type="radio"
                  class="radio"
                  id="direction"
                  name="category"
                />
                <label for="direction">Direction</label>
              </div>

              <div class="option">
                <input
                  type="radio"
                  class="radio"
                  id="services"
                  name="category"
                />
                <label for="services">Services généraux</label>
              </div>

              <div class="option">
                <input
                  type="radio"
                  class="radio"
                  id="intendance"
                  name="category"
                />
                <label for="intendance">Intendance</label>
              </div>
              <div class="option">
                <input type="radio" class="radio" id="autre" name="category" />
                <label for="autre">Autre</label>
              </div>
            </div>
            <div class="box-select">
              <div class="selected"></div>
              <input
                type="hidden"
                v-model="signup_form.function"
                :error-messages="validation_form.function"
                required
              />
              <div
                class="label-select"
                :class="{ changeplaceselect: signup_form.function }"
              >
                Fonction
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4 px-md-5 form-group input-icons">
          <i
            @click="switchVisibility"
            class="fa icon"
            :class="{
              'fa-eye': passwordFieldType == 'password',
              'fa-eye-slash': passwordFieldType == 'text',
            }"
          ></i>
          <input
            :type="passwordFieldType"
            name=""
            class="form-control"
            id="password"
            placeholder="Créer mon mot de passe"
            v-model="signup_form.password"
            :error-messages="validation_form.password"
            required
          />
          <div class="label" :class="{ changeplace: signup_form.password }">
            Créer mon mot de passe
          </div>
          <p class="classError text-danger small">
            {{ validation_form.password ? validation_form.password[0] : null }}
          </p>
          <div class="password-validation">
            <p>Votre mot de passe doit contenir au moins :</p>
            <div class="row">
              <div class="col-4">
                <span>
                  <img :src="DeclineSVG" v-if="!valiadtionCaracteres" />
                  8 caractères
                </span>
                <!-- <span>
                  <img :src="DeclineSVG" v-if="!valiadtionMajuscule" />
                  une majuscule
                </span> -->
              </div>
              <div class="col-8">
                <span>
                  <img :src="DeclineSVG" v-if="!valiadtionChiffres" />
                  un chiffre
                </span>
                <!-- <span>
                  <img :src="DeclineSVG" v-if="!valiadtionSignes" />
                  deux signes tels que !#@&;+)
                </span> -->
              </div>
            </div>
            <img
              :src="PasswordSVG"
              id="myBtn"
              @click="generatePassword(1, 2, 2, 7)"
              class="svg-password"
            />
          </div>
        </div>
        <div class="col-md-4 px-md-5 mt-4 mt-md-0 form-group">
          <input
            :type="passwordFieldType"
            name=""
            class="form-control"
            placeholder="Confirmer mon mot de passe"
            id="repassword"
            v-model="signup_form.password_confirmation"
            :error-messages="validation_form.password_confirmation"
            required
          />
          <div
            class="label"
            :class="{ changeplace: signup_form.password_confirmation }"
          >
            Confirmer mon mot de passe
          </div>
          <p class="classError text-danger small">
            {{
              validation_form.password_confirmation
                ? validation_form.password_confirmation[0]
                : null
            }}
          </p>
        </div>
      </div>
      <div class="row mt-5 mx-auto ps-3">
        <div class="col-md-5">
          <label class="form-check-label" for="flexCheckChecked1">
            <input
              class="form-check-input me-2"
              type="checkbox"
              value="1"
              id="flexCheckChecked1"
              @change="handleCheckboxClicked"
              :checked="signup_form.flexCheckChecked1"
            />
            J'ai lu et accepté la
            <a @click="handleCheckboxClicked" class="theme-color anchor">
              politique de gestion des données personnelles</a
            ></label
          >
        </div>
        <div class="col-md-6">
          <label class="form-check-label" for="flexCheckChecked2">
            <input
              class="form-check-input me-2"
              type="checkbox"
              value="1"
              id="flexCheckChecked2"
              @change="handleCheckboxClickedTwo"
              :checked="signup_form.flexCheckChecked2"
            />
            Avoir lu et accepté les
            <a @click="handleCheckboxClickedTwo" class="theme-color anchor">
              conditions d’utilisation
            </a>
            de la plateforme</label
          >
        </div>
      </div>
      <div class="row my-5">
        <div class="col d-flex align-items-center justify-content-center">
          <vue-recaptcha
            sitekey="6LfgapwdAAAAAKojMQi_dFvgKOg8_CbztUJepykb"
            :loadRecaptchaScript="true"
            ref="recaptcha"
            type="invisible"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
          >
          </vue-recaptcha>
          <span
            class="classError text-danger small"
            v-if="validation_form.recaptcha"
          >
            <ul>
              <li v-for="error in validation_form.recaptcha" :key="error">
                {{ error }}
              </li>
            </ul>
          </span>
          <button
            type="submit"
            class="btn-form ml-1 ms-3"
            value="Valider"
            @click="submit"
            :class="{
              changecolorbtn:
                signup_form.firstName &&
                signup_form.lastName &&
                signup_form.email &&
                signup_form.phone &&
                signup_form.zipcode &&
                signup_form.ville &&
                signup_form.school &&
                signup_form.school_type &&
                signup_form.function &&
                signup_form.password &&
                signup_form.password_confirmation &&
                signup_form.flexCheckChecked1 &&
                signup_form.flexCheckChecked2,
            }"
            :disabled="
              !signup_form.firstName ||
              !signup_form.lastName ||
              !signup_form.email ||
              !signup_form.phone ||
              !signup_form.zipcode ||
              !signup_form.ville ||
              !signup_form.school ||
              !signup_form.school_type ||
              !signup_form.function ||
              !signup_form.password ||
              !signup_form.password_confirmation ||
              !signup_form.flexCheckChecked1 ||
              !signup_form.flexCheckChecked2
            "
          >
            <span v-if="load">Valider</span
            ><moon-loader
              :loading="!load"
              color="white"
              size="30px"
              align="center"
            ></moon-loader>
          </button>
        </div>
      </div>
      <div class="mb-4">
        <p class="d-flex justify-content-center text-buttom-connect">
          Déjà un compte ?
          <a href="/signin" class="text-seconnect"> Se connecter</a>
        </p>
      </div>
    </div>
    <div class="circle-space"></div>
    <div class="pack-space"></div>
    <Policypopup @dialog-closed="eventHandler"></Policypopup>
    <CGUpopup @dialog-closed="eventHandlerTwo"></CGUpopup>

    <!-- Toast copy -->
    <div class="toast">
      <div class="toast-body">Mot de passe copier dans le presse-papiers</div>
    </div>
    <!-- End -->
  </div>
</template>

<script>
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import {
  getByToken,
  signUpWithoutToken,
  signUpUsingToken,
} from "@/services/appApi";
import Policypopup from "@/components/Policypopup.vue";
import CGUpopup from "@/components/CGUpopup.vue";
import { selectbox, drift } from "../../native";
import VueRecaptcha from "vue-recaptcha";
import {
  containsAtlOneUpper,
  containsAtlTwoSymbols,
  containsAtlTwoNumbers,
} from "../../helpers";

export default {
  name: "SignUpPage",
  computed: {
    PasswordSVG() {
      return require("./../../assets/password.svg");
    },
    DeclineSVG() {
      return require("./../../assets/accept.svg");
    },
    valiadtionCaracteres() {
      return this.signup_form.password.length > 7;
    },
    valiadtionMajuscule() {
      return containsAtlOneUpper(this.signup_form.password);
    },
    valiadtionChiffres() {
      return containsAtlTwoNumbers(this.signup_form.password);
    },
    valiadtionSignes() {
      return containsAtlTwoSymbols(this.signup_form.password);
    },
    disableInputs() {
      return !!this.$route.query.token;
    },
  },
  data() {
    return {
      passwordFieldType: "password",
      characters: "a-z,A-Z,0-9,#",
      open: false,
      load: true,
      signup_form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        zipcode: "",
        ville: "",
        school: "",
        school_type: "",
        function: "",
        password: "",
        password_confirmation: "",
        flexCheckChecked1: "",
        flexCheckChecked2: "",
        recaptcha: "",
      },
      validation_form: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        zipcode: null,
        ville: null,
        school: null,
        school_type: null,
        function: null,
        password: null,
        password_confirmation: null,
      },
      modalitiesWasAccepted: false,
      cguWasAccepted: false,
      charteWasAccepted: false,
    };
  },
  mounted() {
    // if (this.$full_url.includes(this.$stage_url)) {
    //   selectbox(this.signup_form);
    //   drift();
    //   this.getDataByToken();
    //   this.ifLogin();
    // } else {
    //   if (this.$route.query.token) {
    selectbox(this.signup_form);
    drift();
    this.getDataByToken();
    this.ifLogin();
    // } else {
    //   this.$router.push({ name: "landingpageWithoutToken" });
    // }
    // }
  },
  components: {
    MoonLoader,
    "vue-recaptcha": VueRecaptcha,
    Policypopup,
    CGUpopup,
  },
  methods: {
    eventHandler() {
      this.signup_form.flexCheckChecked1 = true;
    },
    eventHandlerTwo() {
      this.signup_form.flexCheckChecked2 = true;
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.signup_form.recaptcha = recaptchaToken;
      this.validateCaptcha = true;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    ifLogin() {
      this.$store.getters.getUser
        ? this.$router.push({ name: "espaceco" })
        : null;
    },
    reset_validation() {
      this.validation_form = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        zipcode: null,
        ville: null,
        school: null,
        school_type: null,
        function: null,
        password: null,
        password_confirmation: null,
        recaptcha: null,
      };
    },
    submit() {
      this.load = false;
      if (this.$route.query.token) {
        signUpUsingToken(this.$route.query.token, this.signup_form)
          .then(() => {
            this.$router.push({ name: "landingpageWithoutToken" });
          })
          .catch(({ response }) => {
            this.validation_form = response.data.errors;
          })
          .finally(() => {
            this.load = true;
          });
      } else {
        signUpWithoutToken(this.signup_form)
          .then(() => {
            this.$router.push({
              name: "ConfirmationEmail",
            });
          })
          .catch(({ response }) => {
            this.validation_form = response.data.errors;
          })
          .finally(() => {
            this.load = true;
          });
      }
    },
    getDataByToken() {
      if (this.$route.query.token) {
        getByToken(this.$route.query.token)
          .then((resp) => {
            if (resp.data.message === "user not found") {
              this.$router.push({ name: "landingpageWithoutToken" });
            } else {
              resp.data.is_verified
                ? this.$router.push({ name: "signin" })
                : "";
              this.signup_form.firstName = resp.data.first_name;
              this.signup_form.lastName = resp.data.last_name;
              this.signup_form.email = resp.data.email;
            }
          })
          .catch(({ response }) => {
            this.validation_form = response.data.errors;
          })
          .finally(() => {});
      }
    },

    generatePassword(
      letters = 1,
      lettersU = 1,
      numbers = 2,
      char = 2,
      either = 7
    ) {
      var chars = [
        "abcdefghijklmnopqrstuvwxyz", // letters
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ", // letters
        "0123456789",
        "!#@&;+)",
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", // either
      ];

      let password = [letters, lettersU, numbers, char, either]
        .map(function (len, i) {
          return Array(len)
            .fill(chars[i])
            .map(function (x) {
              return x[Math.floor(Math.random() * x.length)];
            })
            .join("");
        })
        .concat()
        .join("")
        .split("")
        .sort(function () {
          return 0.5 - Math.random();
        })
        .join("");

      navigator.clipboard.writeText(password);

      this.signup_form.password = password;
      this.signup_form.password_confirmation = password;
    },

    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    handleCheckboxClicked() {
      if (!this.signup_form.flexCheckChecked1) {
        window.$("#CGUModal").modal("show");
        this.signup_form.flexCheckChecked1 = false;
      }
    },
    handleCheckboxClickedTwo() {
      if (!this.signup_form.flexCheckChecked2) {
        window.$("#CGUModalTwo").modal("show");
        this.signup_form.flexCheckChecked2 = false;
      }
    },
  },
};
</script>

<style scoped>
.anchor {
  margin: 0;
}

.label-select {
  position: absolute;
  top: 0.5em;
  left: 1em;
  color: #867d7d;
  transition: all 0.4s;
  pointer-events: none;
  font-size: 14px;
}

.changeplaceselect {
  position: absolute;
  top: -1.6em;
  left: 0em;
  transition: all 0.4s;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #333333;
}

.changeplace {
  position: absolute;
  top: -1.6em;
  left: 4em;
  transition: all 0.4s;
  opacity: 1;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #333333;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .changeplace {
    left: 1em;
  }
}
input#flexCheckChecked2,
input#flexCheckChecked1 {
  margin-top: 0;
  margin-bottom: 0;
}

.password-validation .row {
  max-width: 350px;
}
.password-validation p {
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.30000001192092896px;
  color: #292a3b;
  margin: 17px 0 5px;
}
.password-validation span {
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
  color: #292a3b;
  display: block;
  margin: 3px 0px;
}
.svg-password {
  margin-top: 15px;
  cursor: pointer;
}
.icon {
  padding: 10px;
  min-width: 50px;
  text-align: center;
  top: 1px;
  position: absolute;
  right: 45px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .icon {
    right: 12px;
    top: -2px;
  }
}
.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
}
.recaptcha {
  display: inline-block;
  margin-right: 12px;
}
label {
  cursor: pointer;
}
.text-compte {
  font-size: 24px;
  margin-bottom: 0;
}
.text-buttom-connect {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.text-seconnect {
  color: #882a3e;
  text-decoration: none;
  padding-left: 5px;
}
@media only screen and (max-width: 668px) {
  /* For mobile phones: */
  .text-seconnect {
    color: #ffffff;
    text-decoration: none;
    padding-left: 5px;
  }
}
.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: white;
  color: #882a3e;
  font-family: Gotham;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  text-align: center;
  width: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .form-group {
    margin-top: 1rem;
  }
}
</style>