<template>
  <div>
    <div class="container">
      <div class="row" v-if="module">
        <div class="col mt-md-3">
          <h4>{{ courses[0].module.title }}</h4>
          <h2>{{ indexQuestion }}/{{ questions.length }}</h2>
        </div>
        <div class="col-2 text-end pleinEcran" v-if="!pleinEcran">
          <img
            src="/img/svg/pein-ecran-expand.svg"
            class="cursor-pointer"
            @click="FullScreenEcran"
          />
        </div>
        <div class="col text-end pleinEcran" v-else v-show="pleinEcran">
          <img
            src="/img/svg/pein-ecran.svg"
            class="cursor-pointer"
            @click="FullScreenEcran"
          />
        </div>
      </div>
      <div class="mt-md-5" v-if="questions">
        <div class="row">
          <div class="col-2 d-none d-sm-block"></div>
          <div class="col-md-10 col-sm-10 col-12 mt-5">
            <h5>{{ indexQuestion }} - {{ activeQuestion.content }}</h5>
            <p class="text-top">
              {{
                activeQuestion.type === "many"
                  ? "Plusieurs réponses possibles"
                  : "" || activeQuestion.type === "one"
                  ? "une seule réponse possible"
                  : ""
              }}
            </p>
          </div>
        </div>
        <div class="mt-4 row">
          <div class="col-3 d-none d-sm-block"></div>
          <div class="col-md-9 col-sm-9 col-12">
            <ul>
              <li
                class="cursor-pointer"
                v-for="answer in activeQuestion.answers"
                :key="answer.id"
                :class="{
                  answerActive: response && response.includes(answer.id),
                }"
                @click="chooseAnswer(answer.id)"
              >
                {{ answer.content }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="class-top">
        <div class="row">
          <div
            class="
              col-md-11 col-sm-11 col-12
              d-flex
              justify-content-md-end justify-content-center
            "
          >
            <button
              class="btn-valid"
              :disabled="!response.length > 0"
              :class="{ changeColorBtn: response.length }"
              @click="validerAnswer"
            >
              valider
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getQuestionsByModule,
  addUserAnswers,
  getCoursesByModule,
} from "@/services/appApi";

export default {
  data() {
    return {
      courses: null,
      module: null,
      response: [],
      answers: [],
      pleinEcran: false,
    };
  },
  computed: {
    questions() {
      return this.$store.getters.getQuestions;
    },
    activeQuestion() {
      return this.questions.find((e) => e.response === null);
    },
    indexQuestion() {
      return (
        this.questions.findIndex((e) => e.id === this.activeQuestion.id) + 1
      );
    },
  },

  mounted() {
    this.fetchData();
    this.getCoursesByModuleMe();
    this.fullscreen();
  },
  methods: {
    getCoursesByModuleMe() {
      getCoursesByModule({
        params: {
          moduleId: this.$route.params.id,
        },
      })
        .then((resp) => {
          this.courses = resp.data;
        })
        .catch(({ response }) => {
          this.validation_form = response.data.errors;
        });
    },
    check() {
      if (!window.screenTop && !window.screenY) {
        this.pleinEcran = false;
      } else {
        this.pleinEcran = true;
      }
    },
    ClickEcran() {},
    chooseAnswer(answer) {
      if (this.response.includes(answer)) {
        this.response = this.response.filter((item) => item !== answer);
      } else {
        this.activeQuestion.type === "many"
          ? this.response.push(answer)
          : (this.response = [answer]);
      }
    },
    validerAnswer() {
      let module_id = this.module.id;
      let current = this.activeQuestion.id;
      this.response.forEach((element) => {
        this.answers.push(element);
      });
      let questions = this.questions.map((e) => {
        if (e.id == current) {
          e.response = this.response;
          this.response = [];
        }
        return e;
      });

      this.$store.dispatch("set_answers", { questions, module_id });
      if (!this.activeQuestion) {
        let userId = this.$store.getters.getUser;
        addUserAnswers({
          answers: this.answers,
          userId: userId.id,
          moduleId: module_id,
        })
          .then(() => {
            // let module_id = null
            // let questions = null
            // this.$store.dispatch("set_questions",{ questions ,module_id });
            this.$router.push({
              name: "quizzreponse",
              params: { id: this.module.id },
            });
          })
          .catch(() => {});
      }
    },
    fetchData() {
      getQuestionsByModule(this.$route.params.id)
        .then((resp) => {
          this.module = resp.data.module;
          let questions = resp.data.questions;
          // if(this.questions){
          let module_id = this.module.id;
          questions = questions.map((obj) => {
            obj.response = null;
            return obj;
          });
          this.$store.dispatch("set_questions", { questions, module_id });
          // }
        })
        .catch(() => {});
    },
    fullscreen() {
      if (document.fullscreenElement) this.pleinEcran = true;
      else this.pleinEcran = false;
    },

    FullScreenEcran() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        this.pleinEcran = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          this.pleinEcran = false;
        }
      }
      if (document.fullscreenElement);
      else this.pleinEcran = true;
    },
  },
};
</script>
<style scoped>
h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #882a3e;
  padding-left: 4rem;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  h4 {
    padding-left: 0rem;
  }
}
h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-left: 4rem;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .pleinEcran {
    display: none;
  }
  h2 {
    padding-left: 0rem;
  }
}
h5 {
  font-family: Poppins;
  font-weight: bold;
  font-size: 24px;
}
ul > li {
  font-family: Poppins;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
.text-top {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.btn-valid {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  background-color: white;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 23.5px;
  width: 197px;
  height: 47px;
}
.class-top {
  margin-bottom: 2rem;
}

.answerActive {
  transition: all 0.3s ease-in;
  font-weight: bolder;
  padding-left: 1rem;
}

.cursor-pointer {
  cursor: pointer;
  list-style: outside;
  user-select: none;
}

ul {
  list-style-type: none;
  margin: 0;
}
.changeColorBtn {
  background: #000000;
  border-radius: 23.5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #f6f4ed;
}
</style>