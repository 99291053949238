<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto my-5">
                    <h1 class="space-heading text-center">Redéfinnisez votre mot de passe</h1>
                    <h3 class="title-signin text-center">Veuillez saisir votre nouveau mot de passe ci-dessous</h3>
                </div>
            </div>
            <div class="row class-top">
                <div class="col-md-6 px-md-5 mt-2 mx-auto form-group">
                    <input type="password" name="" class="form-control" id="password"
                    v-model="reset_form.password"
                    placeholder="Nouveau mot de passe">
                    <div class="label" for="password" :class="{changeplaceLabel:reset_form.password}">Nouveau mot de passe</div>
                </div>
                <div class="col-md-6 px-md-5 mt-md-2 mt-sm-2 mt-4 mx-auto form-group">
                    <input type="password" name="" class="form-control" id="password_confirmation"
                    v-model="reset_form.password_confirmation" 
                    placeholder="Confirmer nouveau mot de passe">
                    <div class="label" for="password_confirmation" :class="{changeplaceLabel:reset_form.password_confirmation}">Confirmer nouveau mot de passe</div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-8 mx-auto">
                    <p class="classError text-danger text-center">
                        {{ validation_form.password ? validation_form.password[0] : null }}
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12 d-flex justify-content-center mt-3">
                    <button class="btn-valide" value="Valider"
                    :class="{changecolorbtnvalid:reset_form.password && reset_form.password_confirmation}"
                    :disabled="!reset_form.password || !reset_form.password_confirmation"
                    @click="submit">
                        <span v-if="isLoading">Valider</span
                        ><moon-loader
                        :loading="!isLoading"
                        color="white"
                        size="30px"
                        align="center"
                        ></moon-loader>
                    </button>
                </div>
            </div>
        </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
    </div>
</template>

<script>
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import {ResetPassword} from "@/services/appApi";
import {drift} from "../../native"
export default {
  name: "ResetPassword",
  data() {
    return {
        isLoading: true,
        reset_form: {
            password: '',
            password_confirmation: '',
            reset_token: this.$route.query.reset_token,
            email: this.$route.query.email,
        },
        validation_form:{
        password: null,
        password_confirmation: null,
      }
    }
  },
  mounted() {
    this.drift();
  },
  components: {
      MoonLoader,
  },
  methods: {
    drift(){
        drift();
    },
    reset_validation() {
      this.validation_form = {
        password: null,
        password_confirmation: null,
      }
    },
    submit() {
      this.isLoading = false;
      this.reset_validation();
      ResetPassword(this.reset_form)
      .then(() => {
        this.$router.push({
            name: 'PasswordChange'
          });
      })
      .catch(({response}) => {
        this.isLoading = true;
        this.validation_form = response.data.errors;
      })
      .finally(() => {
        this.isLoading = true;
      });
    }
  },
}
</script>

<style scoped>
 .changeplaceLabel{
    position: absolute;
    top: -1.6em;
    left: 4em;
    transition: all 0.4s;
    opacity: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #333333;
  }
  @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .changeplaceLabel {
    left: 1em;
  }
}
.btn-valide{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #828282;
    width: 206px;
    height: 49px;
    background: #F2F2F2;
    border-radius: 5px;
    border: none;
}
.changecolorbtnvalid{
    background: #333333;
    color: #F2F2F2;
}
.class-top{
    margin-top: 6rem;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .class-top{
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
}
</style>