export default {
  // app_url: "http://127.0.0.1:8000/api",
  // stage_url: "http://localhost:8080",
  // prod_url: "http://localhost:8081",

  app_url: "https://stage.cheeseexpert.veniseactivation.com/webservice/api",
  stage_url: "https://stage.cheeseexpert.veniseactivation.com",
  prod_url: "https://expertfromage.fr",

  base_name: "",
};
