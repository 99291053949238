<template>
    <div>
        <div class="container zone">
            <div class="row">
                <router-link :to="{name:'espaceco'}">
                    <svg class="img-back" width="25" height="9" viewBox="0 0 25 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-2.31168e-06 4.5L4.7671 -1.72101e-06L6.28943 1.43007L4.12127 3.48863L25 3.48863L25 5.51136L4.12126 5.51136L6.28943 7.56992L4.7671 9L-2.31168e-06 4.5Z" fill="black"/>
                    </svg>              
                </router-link>
                <div class="col-md-12 my-4">
                    <div class="opinion">Donnez-nous votre retour d’expérience !</div>
                    <div class="line"></div>
                </div>
            </div>
            <div class="row mx-auto mb-4">
                <div class="col-md-8  my-1">
                    <div class="prev cursor-pointer" :class="{active:isActive==1}" @click="isActive=1">
                        <span class="icone">
                            <svg width="36" height="43" viewBox="0 0 36 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.32513 8.63855L0 8.35079C0.0475867 6.91208 0.517727 5.51922 1.35178 4.34597C2.18583 3.17273 3.34686 2.27102 4.69005 1.75335C6.03324 1.23567 7.49912 1.12494 8.90484 1.43496C10.3106 1.74498 11.5939 2.46202 12.5947 3.49666C13.5956 4.53129 14.2696 5.83772 14.5328 7.25295C14.796 8.66819 14.6366 10.1296 14.0746 11.4548C13.5127 12.7801 12.5729 13.9106 11.3726 14.7052C10.1723 15.4999 8.76462 15.9235 7.32513 15.9233C6.9465 15.925 6.56834 15.8963 6.19431 15.8375L7.32513 8.63855Z" fill="#27AE60"/>
                            <path d="M28.7502 7.32617L21.4857 6.31651C21.7124 4.63352 22.5196 3.0825 23.7679 1.93117C25.0163 0.779845 26.6274 0.100452 28.3232 0.0102876C30.019 -0.0798766 31.693 0.424843 33.0564 1.43731C34.4198 2.44978 35.3869 3.90646 35.7908 5.55591C36.1947 7.20537 36.0101 8.94409 35.2688 10.4719C34.5275 11.9998 33.276 13.2209 31.7304 13.9245C30.1849 14.6281 28.4421 14.77 26.8031 14.3257C25.164 13.8814 23.7315 12.8788 22.7528 11.491L28.7502 7.32617Z" fill="#27AE60"/>
                            <path d="M19.4664 30.6244L31.022 27.04C31.3901 28.1992 31.5774 29.4082 31.5773 30.6244C31.5773 33.8377 30.3008 36.9195 28.0286 39.1917C25.7564 41.4638 22.6747 42.7403 19.4613 42.7403C16.248 42.7403 13.1662 41.4638 10.894 39.1917C8.62182 36.9195 7.34533 33.8377 7.34533 30.6244C7.34661 29.9477 7.40402 29.2722 7.51697 28.605L19.4664 30.6244Z" fill="#27AE60"/>
                            </svg>

                        </span>
                        Satifait(e)</div>                   
                </div>
                <div class="col-md-8 my-1">
                    <div class="prev cursor-pointer" :class="{active:isActive==2}" @click="isActive=2">
                        <span class="icone">
                            <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.15136 7.0349L5.46704 0.199015C6.01821 0.0658115 6.5838 -0.00101078 7.15136 1.15547e-05C8.70277 0.00203334 10.2113 0.500892 11.4489 1.42115C12.6864 2.34142 13.5857 3.63303 14.0106 5.10066C14.4356 6.56829 14.3631 8.13214 13.8042 9.55567C13.2454 10.9792 12.2304 12.185 10.9129 12.9908C9.5954 13.7965 8.04697 14.1584 6.50181 14.0216C4.95665 13.8848 3.49879 13.2569 2.34869 12.2327C1.19859 11.2086 0.418795 9.8439 0.12725 8.34509C-0.164296 6.84628 0.0482581 5.29485 0.732765 3.92542L7.15136 7.0349Z" fill="#F2994A"/>
                            <path d="M27.8534 9.03494L25.0614 2.56723C26.6182 1.92328 28.3562 1.84114 29.9685 2.33531C31.5808 2.82948 32.9638 3.86816 33.8731 5.26794C34.7824 6.66771 35.1596 8.33852 34.9381 9.98531C34.7166 11.6321 33.9106 13.1489 32.6624 14.2679C31.4142 15.3869 29.8042 16.036 28.1167 16.1007C26.4292 16.1654 24.7727 15.6414 23.4399 14.6214C22.107 13.6013 21.1834 12.1508 20.8323 10.526C20.4811 8.90114 20.725 7.20652 21.5208 5.74139L27.8534 9.03494Z" fill="#F2994A"/>
                            <path d="M16.9231 35.7964L5.20373 32.4879C5.95061 29.9246 7.54452 27.6792 9.73364 26.1064C11.9227 24.5336 14.5823 23.7231 17.2919 23.803C20.0016 23.8829 22.607 24.8486 24.6964 26.5475C26.7858 28.2465 28.2402 30.5817 28.8296 33.1844L16.9231 35.7964Z" fill="#F2994A"/>
                            </svg>
                        </span>
                        Mitigé(e)</div> 
                </div>
                <div class="col-md-8 my-1">
                    <div class="prev cursor-pointer" :class="{active:isActive==3}" @click="isActive=3">
                        <span class="icone">
                            <svg width="35" height="31" viewBox="0 0 35 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.71066 0.328613L13.0812 0.418248C13.0648 1.94485 12.5823 3.4293 11.6993 4.66967C10.8164 5.91005 9.57579 6.84614 8.14639 7.35058C6.71699 7.85502 5.16814 7.90333 3.71054 7.48895C2.25293 7.07457 0.957352 6.2176 7.62939e-06 5.03463L5.71066 0.328613Z" fill="#EB5757"/>
                            <path d="M27.6295 0.328676L34.9951 0C34.9951 0.109558 34.9951 0.219118 34.9951 0.328676C34.9974 1.95834 34.4681 3.54356 33.4888 4.84018C32.5095 6.1368 31.1345 7.07291 29.5756 7.50429C28.0166 7.93567 26.3602 7.83839 24.8615 7.22746C23.3628 6.61654 22.1049 5.52585 21.2816 4.12337L27.6295 0.328676Z" fill="#EB5757"/>
                            <path d="M11.7523 25.4126H28.005C28.0043 26.3642 27.9217 27.314 27.758 28.2512C27.5963 29.1877 27.3516 30.1077 27.0268 31.0001L11.7523 25.4126Z" fill="#EB5757"/>
                            </svg>
                        </span>
                        Mécontent(e)</div> 
                </div>
            </div>
            <div class="area">
                <div class="label">Message :</div>
                <textarea v-model="message" name="" id="" cols="30" rows="10" placeholder="Ecrivez ici vos retour (optionnel)">
                     
                </textarea>
            </div>
        </div>
        <div class="container mb-4 pb-5">
            <div class="row mt-5">
                <div class="col-md-2 mx-auto">
                    <button type="submit" 
                    @click="makeOpinion()" 
                    class="btn-form" 
                    :class="{changecolorbtn:message && isActive}" 
                    :disabled="!isActive || !message">
                        <span v-if="!load">Envoyer</span>
                            <moon-loader
                            :loading="load"
                            color="white"
                            size="30px"
                            align="center"
                            ></moon-loader>
                    </button>
                </div>
            </div>
        </div>

<!-- Start Snackbar -->
<div
    class="modal fade"
    id="Snackbar">
    <div class="modal-dialog d-flex justify-content-end" role="document">
      <div class="modal-content align-self-end">
        <div class="row d-flex justify-content-end">
              <p class="text-class">Merci de vous avoir fait par de vos retours ! Lorem ipsum dolor sit amme</p>
        </div>
    </div>
  </div>
</div>
<!-- End Snackbar -->
    </div>
</template>

<script>
import { makeOpinion } from "@/services/appApi";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
export default {
  name: "questionnaire",
  data(){
      return{
          isActive:null,
          status:null,
          message:null,
          load:false
      }
  },
  components:{
MoonLoader
  },
  mounted() {
      this.ifUserauth()
  },

  methods: {
      ifUserauth(){
    if(!this.$store.getters.getUser){
        this.$router.push({name: 'signin'});
    }
   
},
 makeOpinion()
    {
        let user=this.$store.getters.getUser
        switch (this.isActive) {
            case 1:
                this.like="Satifait(e)"; 
                break;
            case 2:
                this.like="Mitigé(e)";  
                break;
            case 3:
                this.like="Mécontent(e)";  
                break;

        }
        this.load=true
        makeOpinion({
            like:this.like,
            message:this.message,
            userId:user.id
        })
        .then(() => {
            this.isActive=null;
            this.message=null;
            this.load=false;
            window.$('#Snackbar').modal('show')
            setTimeout(() => {
                window.$('#Snackbar').modal('hide')
            }, 3000);
            })
            .catch(() => {
            })
            .finally(() => {
            });
    }
}
}
</script>

<style scoped>
.opinion{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 44px;
line-height: 66px;
}
.line{
width: 477px;
height: 0px;
border: 3px solid #333333;
background-color: #333333;
opacity: 1;
}
.prev{
  font-family: Gotham;
font-style: normal;
font-weight: normal;
font-size: 34px;
line-height: 41px;
}
textarea{
    width: 739px;
    height: 261px;
    resize: none;
    padding-left: 10px;
    padding-top: 30px;
    border: 1px solid black;
}
@media only screen and (max-width: 768px) {
    textarea{
    width: 450px;
    height: 261px;
    resize: none;
    padding-left: 10px;
    padding-top: 30px;
    border: 1px solid black;
}
}
@media only screen and (min-width: 768px){
.zone{
    padding-left: 300px;
    margin-bottom: 50px;
}
}
.valide{
    margin-left: 250px;
}
.active{
    font-weight: 800;
    padding-left: 10px;
}
.area{
    position: relative;
}
.label{
    position: absolute;
    top:10px;
    left: 10px;
    font-weight: bold;
    color: black;
    opacity: 1;
}

.img-back{
        margin-left: -3rem;
}


/* style popup */
.modal-dialog {
  height: 90vh;
  width: auto;
  max-width: 433px;
  display: flex;
}
.modal-content {
    height: 63px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 0;
    padding: 0.5rem;
    left: 65vh;
}
.text-class{
font-family: Gotham;
font-weight: normal;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
}
/* style popup */

.cursor-pointer{
    cursor: pointer;
}
</style>