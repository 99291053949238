<template>
<div class="hole-space">
        <div class="container content px-5 py-4">
            <div class="row">
                <div class="col-md-7 px-5 mt-5">
                    <h4 class="top-title">Mon profil
                        <svg v-if="!editForm" @click="editForm = !editForm" class="cursor-pointer" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="31" height="31" rx="9.5" stroke="#292A3B"/>
                            <path d="M22.0433 6.00091C22.7562 5.99198 23.3336 6.26142 23.8204 6.70205C24.3204 7.15459 24.8142 7.61681 25.2766 8.10359C26.2735 9.15456 26.2368 10.4765 25.1866 11.5036C24.3141 12.3566 23.4197 13.1895 22.5347 14.0305L13.2675 22.8357C12.751 23.3262 12.2502 23.8525 11.5052 24.0214C10.5302 24.2447 9.54897 24.4367 8.57006 24.6392C8.01056 24.7553 7.45028 24.8625 6.89001 24.9764C6.27417 25.0984 5.88761 24.7367 6.02925 24.1479C6.36808 22.7099 6.7202 21.2756 7.06138 19.8384C7.19271 19.3044 7.48443 18.8182 7.90101 18.4391L20.0651 6.8725C20.6191 6.34181 21.2506 5.97933 22.0433 6.00091ZM21.5135 13.3234C21.4879 13.2709 21.4567 13.2211 21.4204 13.1746C20.4462 12.2427 19.4641 11.3138 18.4962 10.3797C18.2786 10.1698 18.1574 10.3306 18.0188 10.4631C14.9311 13.4001 11.8464 16.3406 8.7649 19.2846C8.50589 19.5332 8.32591 19.8987 8.21714 20.2411C8.01526 20.8923 7.89084 21.5652 7.73746 22.2306C7.63339 22.6817 7.53558 23.1342 7.4315 23.6038C7.5073 23.6092 7.58341 23.6092 7.65921 23.6038C8.58962 23.4193 9.51298 23.1952 10.4504 23.059C11.2329 22.9459 11.8081 22.5879 12.3519 22.0654C15.2965 19.237 18.263 16.4324 21.2217 13.6189C21.3226 13.5251 21.4173 13.4224 21.5127 13.3234H21.5135ZM19.1957 9.39497C20.2396 10.3789 21.2397 11.3183 22.2342 12.2643C22.3852 12.4079 22.4979 12.4228 22.6544 12.271C23.2022 11.741 23.7726 11.2289 24.3071 10.6908C24.9167 10.073 24.8979 9.39274 24.2774 8.78315C23.8509 8.36559 23.4221 7.94952 22.9713 7.55652C22.3719 7.03551 21.634 7.05337 21.0495 7.59299C20.5072 8.09243 19.9759 8.60377 19.4422 9.11213C19.3624 9.18731 19.2975 9.27588 19.195 9.39497H19.1957Z" fill="#292A3B"/>
                        </svg>
                        <svg v-else @click="editForm = !editForm"  class="cursor-pointer" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="10" fill="#882A3E"/>
                            <path d="M22.0433 6.00091C22.7562 5.99198 23.3336 6.26142 23.8204 6.70205C24.3204 7.15459 24.8142 7.61681 25.2766 8.10359C26.2735 9.15456 26.2368 10.4765 25.1866 11.5036C24.3141 12.3566 23.4197 13.1895 22.5347 14.0305L13.2675 22.8357C12.751 23.3262 12.2502 23.8525 11.5052 24.0214C10.5302 24.2447 9.54897 24.4367 8.57006 24.6392C8.01056 24.7553 7.45028 24.8625 6.89001 24.9764C6.27417 25.0984 5.88761 24.7367 6.02925 24.1479C6.36808 22.7099 6.7202 21.2756 7.06138 19.8384C7.19271 19.3044 7.48443 18.8182 7.90101 18.4391L20.0651 6.8725C20.6191 6.34181 21.2506 5.97933 22.0433 6.00091ZM21.5135 13.3234C21.4879 13.2709 21.4567 13.2211 21.4204 13.1746C20.4462 12.2427 19.4641 11.3138 18.4962 10.3797C18.2786 10.1698 18.1574 10.3306 18.0188 10.4631C14.9311 13.4001 11.8464 16.3406 8.7649 19.2846C8.50589 19.5332 8.32591 19.8987 8.21714 20.2411C8.01526 20.8923 7.89084 21.5652 7.73746 22.2306C7.63339 22.6817 7.53558 23.1342 7.4315 23.6038C7.5073 23.6092 7.58341 23.6092 7.65921 23.6038C8.58962 23.4193 9.51298 23.1952 10.4504 23.059C11.2329 22.9459 11.8081 22.5879 12.3519 22.0654C15.2965 19.237 18.263 16.4324 21.2217 13.6189C21.3226 13.5251 21.4173 13.4224 21.5127 13.3234H21.5135ZM19.1957 9.39497C20.2396 10.3789 21.2397 11.3183 22.2342 12.2643C22.3852 12.4079 22.4979 12.4228 22.6544 12.271C23.2022 11.741 23.7726 11.2289 24.3071 10.6908C24.9167 10.073 24.8979 9.39274 24.2774 8.78315C23.8509 8.36559 23.4221 7.94952 22.9713 7.55652C22.3719 7.03551 21.634 7.05337 21.0495 7.59299C20.5072 8.09243 19.9759 8.60377 19.4422 9.11213C19.3624 9.18731 19.2975 9.27588 19.195 9.39497H19.1957Z" fill="#F6F4ED"/>
                        </svg>
                    </h4>
                </div>
            </div>
            <div class="row mt-5 px-3" v-if="!editForm">
                <div class="px-5 col-md-4">
                    <span class="champ-profile">Nom : </span>
                    <span class="text-profile">{{account_form.last_name}}</span>
                </div>
                <div class="px-5 col-md-4">
                    <span class="champ-profile">Prénom : </span>
                    <span class="text-profile">{{account_form.first_name}}</span>
                </div>
                <div class="px-5 col-md-4">
                    <span class="champ-profile">Email : </span>
                    <span class="text-profile">{{account_form.email}}</span>
                </div>
                <div class="px-5 mt-5 col-md-4">
                    <span class="champ-profile">Mon école : </span>
                    <span class="text-profile">{{account_form.school}}</span>
                </div>
                <div class="px-5 mt-5 col-md-4">
                    <span class="champ-profile">Type d’école : </span>
                    <span class="text-profile">{{account_form.school_type}}</span>
                </div>
                <div class="px-5 mt-5 col-md-4">
                    <span class="champ-profile">Fonction : </span>
                    <span class="text-profile">{{account_form.function}}</span>
                </div>
            </div>
            <div class="row mt-5 px-3" v-if="editForm">
                <div class="px-5 col-md-4">
                    <div>Nom</div>
                    <input type="text" name="" class="form-control" id="nom" :class="{ 'is-invalid': error_form.lastName }" v-model="validation_form.lastName">
                   <div class="invalid-feedback" v-if="error_form.lastName">
                        {{ error_form.lastName[0] }}
                    </div>
                </div>
                <div class="px-5 col-md-4">
                    <div>Prénom</div>
                    <input type="text" name="" class="form-control" id="Prenom" :class="{ 'is-invalid': error_form.lastName }" v-model="validation_form.firstName">
                    <div class="invalid-feedback" v-if="error_form.firstName">
                        {{ error_form.firstName[0] }}
                    </div>
                </div>
                <div class="px-5 col-md-4">
                    <div>Email</div>
                    <input type="email" name="" class="form-control" id="email" :class="{ 'is-invalid': error_form.email }" v-model="validation_form.email">
                    <div class="invalid-feedback" v-if="error_form.email">
                        {{ error_form.email[0] }}
                    </div>
                </div>
                <div class="px-5 col-md-4 mt-4">
                    <div>Mon école</div>
                    <input type="text" name="" class="form-control" id="mon-ecole" :class="{ 'is-invalid': error_form.school }"
                    v-model="validation_form.school">
                    <div class="invalid-feedback" v-if="error_form.school">
                        {{ error_form.school[0] }}
                    </div>
                </div>
                <div class="px-5 col-md-4 mt-4">
                    <div>Type d’école</div>
                    <input type="text" name="" class="form-control" id="Type-ecole" :class="{ 'is-invalid': error_form.schoolType }"
                    v-model="validation_form.schoolType">
                    <div class="invalid-feedback" v-if="error_form.schoolType">
                        {{ error_form.schoolType[0] }}
                    </div>
                </div>
                <div class="px-5 col-md-4 mt-4">
                    <div>Fonction</div>
           
                    <select lass="form-control" id="fonction" v-model="validation_form.function" :class="{ 'is-invalid': error_form.function }">
                        <option v-for="option in functionsList" :key="option.$key" :value="option"> {{ option }} </option>
                    </select>
                    <div class="invalid-feedback" v-if="error_form.function">
                        {{ error_form.function[0] }}
                    </div>
                </div>
                
                <div class="px-5 col-md-4 mt-4">
                    <div>Ancien mot de passe</div>
                    <input type="password" name="" class="form-control" id="password-ancien" :class="{ 'is-invalid': error_form.passwordAncien }"
                    v-model="validation_form.passwordAncien">
                    <div class="invalid-feedback" v-if="error_form.passwordAncien">
                        {{ error_form.passwordAncien[0] }}
                    </div>
                </div>
                <div class="px-5 col-md-4 mt-4 input-icons">
                    <div>Nouveau mot de passe</div>
                      <i @click="switchVisibility" class="fa icon" :class="{
                            'fa-eye': passwordFieldType == 'password',
                            'fa-eye-slash': passwordFieldType == 'text',
                        }"></i>
                    <input :type="passwordFieldType" name="" class="form-control" id="password" :class="{ 'is-invalid': error_form.password }"
                    v-model="validation_form.password">
                    <div class="invalid-feedback" v-if="error_form.password">
                        {{ error_form.password[0] }}
                    </div>
                    <div class="password-validation">
                        <p>Votre  mot de passe doit contenir au moins :</p>
                        <div class="row">
                        <div class="col-4">
                            <span>
                            <img :src="DeclineSVG" v-if="!valiadtionCaracteres" />
                            8 caractères
                            </span>
                            <span>
                            <img :src="DeclineSVG" v-if="!valiadtionMajuscule" />
                            une majuscule
                            </span>
                        </div>
                        <div class="col-8">
                            <span>
                            <img :src="DeclineSVG" v-if="!valiadtionChiffres" />
                            deux chiffres
                            </span>
                            <span>
                            <img :src="DeclineSVG" v-if="!valiadtionSignes" />
                            deux signes tels que !#@&;+)
                            </span>
                        </div>
                        </div>
                        <img :src="PasswordSVG"  @click="generatePassword(1,2,2,7)" class="svg-password" />
                    </div>
                </div>
                <div class="px-5 col-md-4 mt-4">
                    <div>Confirmer nouveau mot de passe</div>
                    <input :type="passwordFieldType" name="" class="form-control" id="password-confirmation" :class="{ 'is-invalid': error_form.passwordConfirmation }"
                    v-model="validation_form.passwordConfirmation">
                    <div class="invalid-feedback" v-if="error_form.passwordConfirmation">
                        {{ error_form.passwordConfirmation[0] }}
                    </div>
                </div>
                <div class="row d-flex justify-content-center mt-3">
                    <div class="col-md-4 mt-4 d-flex justify-content-md-end justify-content-center">
                        <input type="submit" class="btn-submit" value="Valider" @click="submit" 
                        :disabled="!validation_form.firstName || !validation_form.lastName || !validation_form.email || !validation_form.school || !validation_form.schoolType || !validation_form.function">
                    </div>
                    <div class="col-md-4 mt-4 d-flex justify-content-md-start justify-content-center">
                        <input type="submit" class="btn-form-annuler" value="Annuler" @click="cancel_from">
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import {containsAtlOneUpper, containsAtlTwoSymbols, containsAtlTwoNumbers} from '../../helpers';
import {updateMyProfil} from "@/services/appApi";
export default {
  name: "MyProfile",
    computed: {   
    PasswordSVG() {
      return require('./../../assets/password.svg');
    },
    DeclineSVG() {
      return require('./../../assets/accept.svg');
    },
    valiadtionCaracteres() {
      return this.validation_form.password.length > 8
    },
    valiadtionMajuscule() {
      return containsAtlOneUpper(this.validation_form.password)
    },
    valiadtionChiffres() {
      return containsAtlTwoNumbers(this.validation_form.password)
    },
    valiadtionSignes() {        
      return containsAtlTwoSymbols(this.validation_form.password)
    },
  },
  data(){
      return{
        passwordFieldType: "password",
        characters: "a-z,A-Z,0-9,#",
        user:{},
        functionsList:[
            'Prof / enseignant',
            'Formation',
            'Direction',
            'Service généraux',
            'Intendance',
            'autre'
        ],
        editForm: false,
        account_form: {
            first_name: '',
            last_name: '',
            email: '',
            school: '',
            school_type: '',
            function: '',
            passwordAncien: '',
            password: '',
            passwordConfirmation: '',
            api_token:this.$route.params.token
        },
        validation_form: {
            firstName: '',
            lastName: '',
            email: '',
            school: '',
            schoolType: '',
            function: '',
            passwordAncien: '',
            password: '',
            passwordConfirmation: '',
        },
        error_form: {},
      }
    },
    mounted() {
      this.getDataByToken();
    },
    components: {
    },
    props: {
        editable: Boolean,
    },
    methods: {
    reset_validation() {
      this.validation_form = {
            firstName: null,
            lastName: null,
            email: null,
            school: null,
            schoolType: null,
            function: null,
            passwordAncien: null,
            password: null,
            passwordConfirmation: null,
      };
    },
    getDataByToken(){
        this.editForm = false;
        this.account_form = this.$store.getters.getUser
        this.validation_form.firstName = this.account_form.first_name;
        this.validation_form.lastName = this.account_form.last_name;
        this.validation_form.email = this.account_form.email;
        this.validation_form.school = this.account_form.school;
        this.validation_form.function = this.account_form.function;
        this.validation_form.schoolType = this.account_form.school_type;
        this.validation_form.passwordAncien = null;
        this.validation_form.password = '';
        this.validation_form.passwordConfirmation = '';
    },
    submit() {
        // this.reset_validation();
        updateMyProfil(this.account_form.api_token, this.validation_form)
            .then((response) => {
                this.$store.dispatch('set_user',response.data)
                this.$router.go(this.$router.currentRoute)
            })
            .catch(({response}) => {
                this.error_form = response.data.errors;
            })
            .finally(() => {
            });
    },
    cancel_from() {
      this.getDataByToken();
      this.editForm = false;
    },
    generatePassword(letters=1,lettersU=1, numbers=2,char=2, either=7) {
      var chars = [
      "abcdefghijklmnopqrstuvwxyz", // letters
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ", // letters
      "0123456789",
      "!#@&;+)",
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" // either
      ];

     let password =  [letters,lettersU, numbers, char,either].map(function(len, i) {
        return Array(len).fill(chars[i]).map(function(x) {
          return x[Math.floor(Math.random() * x.length)];
        }).join('');
      }).concat().join('').split('').sort(function(){
        return 0.5-Math.random();
      }).join('')

      this.validation_form.password = password;
      this.validation_form.passwordConfirmation= password;
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },
    }
}
</script>

<style scoped>
.ellipse-text{
    font-family: Gotham;
    font-weight: 450;
    font-size: 18px;
    line-height: 22px;
}
.top-title{
    font-family: Gotham;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
}
.champ-profile{
    font-family: Roboto;
    font-weight: bold;
    font-size: 15px;
}
.text-profile{
    font-family: Roboto;
    font-size: 15px;
}
.cursor-pointer{
    cursor: pointer;
}
.btn-submit {
    width: 206px;
    height: 49px;
    border-radius: 5px;
    border: 0;
    color: #867d7d;
    background-color: #333333 !important;
    color: white;
    transition: all .3s ease-in;
}
.btn-submit:disabled {
   background-color: #F2F2F2 !important;
    color: #867d7d;
        transition: all .3s ease-in;

}
.password-validation .row{
    max-width: 350px;
  }
  .password-validation p{
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: -0.30000001192092896px;
    color: #292A3B;
    margin:17px 0 5px;
  }
  .password-validation span{
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
    color: #292A3B;
    display: block;
    margin:3px 0px;
  }
  .svg-password{
    margin-top:15px;
    cursor:pointer;
  }             
  .icon {
    padding: 10px;
    min-width: 50px;
    text-align: center;
    top: 1.5rem;
    position: absolute;
    right: 45px;
    cursor:pointer;
  }
    
  .input-field {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  .input-icons {
      position: relative;
}
select {
    background: #F2F2F2;
    border: none;
    border-radius: 5px;
    position: relative;
    width: 100%;
    order: 0;
    height: 2.3em;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    outline: none;
}

  .btn-form-annuler{
    background-color: #333333;
    width: 206px;
    height: 49px;
    border-radius: 5px;
    color: white;
    border: 0;
  }
</style>