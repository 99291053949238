function selectbox(form){
    const selected = document.querySelector(".selected");
    const selection = document.querySelector(".selection");
    const boxSelect = document.querySelector(".box-select");
    const optionsContainer = document.querySelector(".options-container");
    const optionsList = document.querySelectorAll(".option");

    selected.addEventListener("click", () => {
    optionsContainer.classList.toggle("active");
    boxSelect.classList.toggle("active");
    });
    selection.addEventListener("mouseleave", () => {
    optionsContainer.classList.remove("active");
    boxSelect.classList.remove("active");
    });
    optionsList.forEach(o => {
    o.addEventListener("click", () => {
        form.function = o.querySelector("label").textContent;
        selected.innerHTML = o.querySelector("label").innerHTML;
        optionsContainer.classList.remove("active");
    });
    });
}

function  drift(){
    const form = document.querySelectorAll(".form-control");
    const label = document.querySelectorAll(".label");
    for (let i = 0; i < form.length; i++) {
    form[i].addEventListener("click", () => {
        label[i].classList.add("change-place");
    });
    label[i].addEventListener("click", () => {
        label[i].classList.add("change-place");
        form[i].focus();
    });
    form[i].addEventListener("blur", () => {
        if(form[i].value.length== 0){
        label[i].classList.remove("change-place");
        }
    });
}}



function toggleDocument()
{
    document.addEventListener("keydown", function(e) {
        if (e.key === "Enter" || e.key === "Escape") {
          toggleFullScreen();
        }
      }, false);
     
}

function toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();

    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }
export {selectbox}
export {drift}
export {toggleDocument}
export {
    
}
