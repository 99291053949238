<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col text-start mt-4">
          <h4>{{ courses[0].module.title }}</h4>
          <h2>{{ questions.length }}/{{ questions.length }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-9 mt-5 d-flex">
          <svg
            class="m-3"
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="20.5"
              cy="20.5"
              r="15.5"
              :stroke="result.status"
              stroke-width="10"
            />
          </svg>
          <h5>
            {{ result.errors }} {{ result.errors > 1 ? "erreurs" : "erreur" }}
          </h5>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-md-3"></div>
        <div class="col-9" v-if="result.status=='#219653'">
          <div v-if="courses[0].module.id==1">
            <p class="class-text"><b><span class="crimson">Félicitations!</span> Vous avez débloqué des cadeaux qui récompensent vos efforts</b></p>
            <p class="class-text">
              Le Chef Pierre Sang et tout son savoir-faire culinaire vous donne rendez-vous pour 3 idées recettes exceptionnelles à base de 3 fromages européens. Des recettes originales parfaites pour inspirer vos élèves et mettre en œuvres leurs compétences.<br> François Robin, un des meilleurs ouvriers de France fromager, vous aide à réaliser un vrai beau plateau de fromage pour les grandes occasions.<br> Quoi de mieux qu’un professionnel pour conseiller de futurs professionnels ?
            </p>
          </div>
          <div v-if="courses[0].module.id==2">
            <p class="class-text"><b><span class="crimson">Félicitations!</span> Vous avez débloqué des cadeaux qui récompensent vos efforts</b></p>
            <p class="class-text">
            Un mapping du goût des fromages, pour découvrir les subtilités de chacun d’entre eux et trouver des fromages qui vous conviennent.<br> Un bon outil pédagogique pour bien ancrer les connaissances de vos élèves sur les produits.          
            </p>
          </div>
        </div>
        <div class="col-9" v-else>
          <div>
            <p class="class-text"><b>Le quizz sur les catégories de fromage est désormais terminé. </b></p>
            <p class="class-text">
              Malheureusement il vous manque quelques bonnes réponses pour valider ce module, mais pas de panique vous pouvez retenter votre chance. Une belle récompense vous attend !            </p>
          </div>
        </div>
      </div>
      <div class="class-top">
        <div class="row">
          <div class="col-md-3"></div>
          <div
            class="col d-flex justify-content-md-start justify-content-center">
          <router-link
            tag="p"
            :to="{ name: 'page1' }"
            class="text-link"
            v-if="this.$route.params.id==1 && result.status=='#219653'"
          >
            Décourvrir les vidéos
            <svg width="24" height="9" viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 4.5L19.4236 9L17.9622 7.56993L20.0436 5.51137H0V3.48863H20.0436L17.9622 1.43007L19.4236 -3.57628e-07L24 4.5Z" fill="black"/>
            </svg>
            </router-link>
          <router-link
            tag="p"
            :to="{ name: 'FromageCard' }"
            class="text-link"
            v-if="this.$route.params.id==2 && result.status=='#219653'"
          >
            Découvrir la carte des fromages
            <svg width="24" height="9" viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 4.5L19.4236 9L17.9622 7.56993L20.0436 5.51137H0V3.48863H20.0436L17.9622 1.43007L19.4236 -3.57628e-07L24 4.5Z" fill="black"/>
            </svg>
            </router-link>
          </div>
        </div>
      </div>
        <div class="mt-5 pt-5 mb-5">
          <router-link
            tag="p"
            :to="{ name: 'espaceco' }"
            class="text-link"
          >
            <svg width="24" height="9" viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-3.93402e-07 4.5L4.57641 -1.69806e-06L6.03785 1.43007L3.95641 3.48863L24 3.48863L24 5.51137L3.95641 5.51136L6.03785 7.56993L4.57641 9L-3.93402e-07 4.5Z" fill="black"/>
            </svg>
            Revenir au menu

            </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserAnswers, userPaticipateToModule, getCoursesByModule } from "@/services/appApi";

export default {
  data() {
    return {
      courses: null,
      module: null,
      result: null,
      pleinEcran: false,
    };
  },

  mounted() {
    this.fetchData();
    this.getCoursesByModuleMe();
    this.fullscreen();
  },
  computed:{
      questions() {
        return this.$store.getters.getQuestions;
      },
  },
  methods: {
    getCoursesByModuleMe() {
            getCoursesByModule({
                params: {
                moduleId: this.$route.params.id,
                },
            })
                .then((resp) => {
                this.courses = resp.data;
                })
                .catch(({ response }) => {
                this.validation_form = response.data.errors;
                });
        },
    fullscreen() {
      if (document.fullscreenElement) this.pleinEcran = true;
      else this.pleinEcran = false;
    },
    fetchData() {
      let userId = this.$store.getters.getUser;
      getUserAnswers({
        moduleId: this.$route.params.id,
        userId: userId.id,
      })
        .then((resp) => {
          this.result = resp.data;
        })
        .catch(() => {
        });
    },
    userPaticipateToModule(moduleId) {
      let userId = this.$store.getters.getUser;
      userPaticipateToModule({
        userId: userId.id,
        moduleId: moduleId,
      })
        .then(() => {
          this.$router.push({ name: `course`, params: { id: moduleId } });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #882A3E;
}
h2{
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
h5 {
  font-family: Poppins;
  font-weight: bold;
  font-size: 44px;
  line-height: 66px;
  width: 345px;
  border-bottom: 3px solid #333333;
}
.class-text {
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  margin-left: 4.5rem;
}
.btn-valid {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;

  background-color: white;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 23.5px;
  width: 197px;
  height: 47px;
}
.class-top {
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.text-link {
  font-family: Poppins;
  font-size: 20px;
  text-decoration-line: underline;
  margin-left: 4.5rem;
  cursor: pointer;
}
.text-link-top {
  font-family: Poppins;
  font-size: 20px;
  text-decoration-line: underline;
  margin-left: 2rem;
  cursor: pointer;
}
.crimson{
  color: #882A3E;
}
</style>
