<template>
<div class="hole-space">
    <div class="container px-4">
        <div class="row">
            <div class="col-md-8 mx-auto my-5">
                <h1 class="title-top text-center">Confirmez votre adresse email</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 mx-auto">
                <p class="text-between text-center">Votre inscription est presque terminée !</p>
                <p class="text-top text-center">Vous allez recevoir un email qui vous permettra<br> 
                de finaliser votre inscription.</p>
            </div>
        </div>
    </div>
            <div class="circle-space"></div>
        <div class="pack-space"></div>
</div>
</template>

<script>
export default {
  name: "ConfirmationEmail",
  data() {
    return {
    }
  },

  components: {

  },
}
</script>

<style scoped>
.title-top{
    color: #882A3E;
    margin-top: 10rem;
    font-family: Gotham;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
}
.text-top{
    color: #333333;
    font-family: Gotham;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
}
.text-between{
    color: #333333;
    margin-top: 1rem;
    font-family: Gotham;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
}
</style>