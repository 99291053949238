<template>
  <nav class="navbar navbar-light navbar-expand-md navigation-clean-button p-0">
    <div class="container-lg position-relative class-flex">
      <!-- mobile nav -->
      <div v-if="toggle" class="text-center">
        <div class="dropdown-content-mobile">
          <div class="flex">
            <div>
              <img
                src="/img/x.png"
                class="img-ignormenu"
                @click="toggle = false"
              />
            </div>
            <a
              class="
                text-decoration-none
                mx-auto
                mt-2
                navbar-link-mobile
                cursor-pointer
              "
              href="/"
              ><img src="/img/Logo-French-1.png"
            /></a>
          </div>
          <div class="navbar-nav">
            <div v-if="!user" class="sign-menu">
              <a
                class="
                  text-decoration-none
                  mx-auto
                  mt-4
                  navbar-link-mobile
                  cursor-pointer
                "
                href="signup"
                >Inscription</a
              >
              <a
                class="
                  text-decoration-none
                  mx-auto
                  mt-4
                  navbar-link-mobile
                  cursor-pointer
                "
                role="button"
                href="/signin"
                >Connexion</a
              >
            </div>
            <div v-else>
              <div class="dropdown-content-mobile">
                <div class="flex">
                  <div>
                    <img
                      src="/img/x.png"
                      class="img-ignormenu"
                      @click="toggle = false"
                    />
                  </div>
                  <a
                    class="
                      text-decoration-none
                      mx-auto
                      mt-2
                      navbar-link-mobile
                      cursor-pointer
                    "
                    href="/"
                    ><img src="/img/Logo-French-1.png"
                  /></a>
                </div>
                <a
                  class="
                    text-decoration-none
                    mx-auto
                    mt-4
                    navbar-link-mobile
                    cursor-pointer
                  "
                  href="/espaceco"
                  >Modules</a
                >
                <a
                  class="
                    text-decoration-none
                    mx-auto
                    mt-4
                    navbar-link-mobile
                    cursor-pointer
                  "
                  href="/myprofile"
                  >Mon Profil</a
                >
                <a
                  class="
                    text-decoration-none
                    mx-auto
                    mt-4
                    navbar-link-mobile
                    cursor-pointer
                  "
                  @click="logout()"
                  >Déconnexion</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end nav mobile -->

      <router-link
        :to="{ name: 'landingpageWithoutToken' }"
        class="navbar-brand bd-highlight logo-link flex-md-fill"
      >
        <img src="/img/Logo-French-1.png" />
      </router-link>
      <span class="bd-highlight flex-md-fill">
        <img class="img-topbar" src="/img/svg/logoTopBar.svg" />
      </span>

      <!-- Menu mobile -->
      <div class="d-flex d-md-none">
        <button
          @click="toggle = !toggle"
          data-bs-toggle="collapse"
          class="navbar-toggler"
          data-bs-target="#navcol-1"
        >
          <span class="visually-hidden">Toggle navigation</span
          ><span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <!-- End menu mobile -->

      <div class="collapse navbar-collapse sign" id="navcol-1">
        <div class="navbar-nav">
          <div v-if="!user" class="sign-menu cnx">
            <!-- <template v-if="showSignupSigninLinks"> -->
              <a class="mx-1" href="signup"
                >Inscription 
                <!-- <span class="navbar-symb">/</span> -->
                </a
              >
              <a class="mx-1" role="button" href="/signin"
                >Connexion</a
              >
            <!-- </template> -->
            <svg
              class="mx-3 navbar-symb"
              width="21"
              height="26"
              viewBox="0 0 21 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 14.1467C5.14223 14.1467 0.723607 17.6401 0 22.176C2.80205 24.356 6.48167 25.6694 10.5 25.6694C14.5183 25.6694 18.2133 24.3424 21 22.176C20.2918 17.6265 15.8578 14.1467 10.5 14.1467Z"
                fill="#1A1A1A"
              />
              <path
                d="M10.8826 12.6286C14.5009 12.6286 17.36 9.95911 17.2685 6.66611C17.1771 3.37312 14.1698 0.703613 10.5515 0.703613C6.93321 0.703613 4.07411 3.37312 4.16553 6.66611C4.25695 9.95911 7.26428 12.6286 10.8826 12.6286Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>
          <div v-else class="dropdown1" @click="clickmenu = !clickmenu">
            <div class="text-decoration-none mx-1 navbar-link user-name">
              <div class="d-flex flex-column">
                <div class="text-right navbar-user">
                  {{ user.first_name }} {{ user.last_name }} <br />
                  {{ user.school }}
                </div>
              </div>
            </div>
            <svg
              class="mx-3 class-svg navbar-symb user-name"
              width="21"
              height="26"
              viewBox="0 0 21 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 14.1467C5.14223 14.1467 0.723607 17.6401 0 22.176C2.80205 24.356 6.48167 25.6694 10.5 25.6694C14.5183 25.6694 18.2133 24.3424 21 22.176C20.2918 17.6265 15.8578 14.1467 10.5 14.1467Z"
                fill="#1A1A1A"
              />
              <path
                d="M10.8826 12.6286C14.5009 12.6286 17.36 9.95911 17.2685 6.66611C17.1771 3.37312 14.1698 0.703613 10.5515 0.703613C6.93321 0.703613 4.07411 3.37312 4.16553 6.66611C4.25695 9.95911 7.26428 12.6286 10.8826 12.6286Z"
                fill="#1A1A1A"
              />
            </svg>
            <div class="dropdown-content-1 classhide" v-if="clickmenu">
              <router-link
                class="text-decoration-none mx-auto mt-2 navbar-link"
                :to="{ name: 'espaceco' }"
                >Modules</router-link
              >
              <router-link
                class="text-decoration-none mx-auto pt-1 navbar-link"
                :to="{ name: 'myprofile' }"
                >Mon Profil</router-link
              >
              <a
                class="
                  text-decoration-none
                  mx-auto
                  mt-2
                  navbar-link
                  cursor-pointer
                "
                @click="logout()"
                >Déconnexion</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      user: null,
      toggle: false,
      clickmenu: false,
    };
  },

  mounted() {
    this.getDataByToken();
  },

  components: {},
  methods: {
    getDataByToken() {
      if (this.$store.getters.getUser) this.user = this.$store.getters.getUser;
    },

    logout() {
      localStorage.removeItem("vuex");
      this.toggle = false;
      this.clickmenu = false;
      const link = this.$router.resolve({ name: "LandingPage" });
      window.location.replace(link.href);
    },
  },
  computed: {
    showSignupSigninLinks() {
      if (
        this.$route.name == "signup" ||
        this.$route.name == "signin" ||
        this.$store.getters.getAccessTocken
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.cnx {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  text-decoration: none;
}
.navbar-link {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}
.navbar-user {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
}
.menu-toggled {
  padding-top: 25px;
}
@media screen and (min-width: 720px) {
  .sign {
    position: absolute;
    top: 2;
    right: 0;
  }
}
@media screen and (max-width: 720px) {
  .sign-menu {
    display: flex;
    flex-direction: column;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
}
svg {
  margin-bottom: 5px;
}
.dropdown {
  position: absolute;
  width: 272px;
  height: 124px;
  top: -120px;
  right: 0;
  opacity: 0;
  display: none;
  transition: all 0.1s;
  z-index: -1;
}
.slide {
  position: absolute;
  width: 272px;
  height: 160px;
  top: 75px;
  right: 0;
  opacity: 1;
  display: block;
  background-color: #eeeeee;
  z-index: 1;
  transition: all 0.4s;
}
.user-name {
  cursor: pointer;
}
a {
  color: black;
}
.compte-menu {
  cursor: pointer;
  position: relative;
  display: flex;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-1 {
  position: absolute;
  background-color: #ffffff;
  min-width: 270px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
  padding-bottom: 10px;
  margin-top: 3.3rem !important;
  border: 1px solid black;
  left: -7rem;
}
.dropdown-content-1 a {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.dropdown1 {
  position: relative;
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}
/* 
.dropdown1:hover .dropdown-content-1 {
  display: block;
  margin-top: 50px;
  margin-left: -80px;
} */
.class-svg {
  margin-top: 0.6rem;
}

@media only screen and (max-width: 568px) {
  /* For mobile phones: */
  .img-topbar {
    width: 100%;
  }
}
.class-flex {
  flex-wrap: nowrap;
}

.dropdown-content-mobile {
  position: fixed;
  background-color: #fcfcfc;
  min-width: 270px;
  flex-direction: column;
  z-index: 10;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 50rem;
}
.navbar-link-mobile {
  font-family: Gotham;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 19px;
  display: block;
  align-items: center;
  text-align: center;
}
.img-ignormenu {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  cursor: pointer;
}
</style>
