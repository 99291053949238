<template>
  <div>
    <h1>{{ video.title }}</h1>
    <hr />
    <p>{{ video.description }}</p>
    <div class="video">
      <video
        :src="require('../../../assets/videos/' + video.src)"
        width="100%"
        max-height="585px"
        controls
      ></video>
      <a
        :href="require('../../../assets/videos/' + video.src)"
        class="download"
        download
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <svg
          class="pop"
          width="128"
          height="39"
          viewBox="0 0 128 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-show="hover"
        >
          <path
            d="M116.842 9.40419V9.61331L116.991 9.76016L126.689 19.3264L116.97 28.9705L116.822 29.1171V29.3254V32.1916C116.82 33.6919 116.215 35.1316 115.137 36.1946C114.059 37.2578 112.596 37.8574 111.069 37.8599C111.069 37.8599 111.069 37.8599 111.069 37.8599L6.65379 37.8205C5.12645 37.8181 3.66372 37.2185 2.58578 36.1552C1.50817 35.0923 0.902921 33.6528 0.900391 32.1525V6.53719C0.900389 5.03526 1.50457 3.59332 2.58254 2.52833C3.66064 1.4632 5.12461 0.86237 6.65341 0.859863H111.089C112.618 0.86237 114.082 1.4632 115.16 2.52833C116.238 3.59332 116.842 5.03526 116.842 6.53719V9.40419Z"
            fill="white"
            stroke="black"
          />
        </svg>
        <span class="text-uppercase telecharger" v-show="hover"
          >Télécharger</span
        >

        <svg
          width="19"
          height="18"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2703 10.6747V0H10.7337V10.6747H8.97852L10.739 13.8192L12.502 16.9636L14.2625 13.8192L16.0229 10.6747H14.2703Z"
            fill="white"
          />
          <path
            d="M22.3829 16.1577H20.9761C20.9754 17.285 20.5354 18.3659 19.7529 19.1628C18.9703 19.9597 17.9092 20.4073 16.8029 20.4073H7.69674C6.59063 20.4073 5.52982 19.9596 4.74768 19.1626C3.96554 18.3657 3.52614 17.2848 3.52614 16.1577H0V21.3336C0 22.0415 0.276006 22.7205 0.767299 23.2211C1.25859 23.7217 1.92493 24.0029 2.61972 24.0029H22.3829C23.0777 24.0029 23.744 23.7217 24.2353 23.2211C24.7266 22.7205 25.0026 22.0415 25.0026 21.3336V16.1577H22.3829Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: ["video"],
  data() {
    return {
      hover: false,
    };
  },
  methods: {},
};
</script>
<style scoped>
.video {
  position: relative;
}
.download {
  position: absolute;
  bottom: 46px;
  right: 14.3px;
  width: 40px;
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.4s;
  z-index: 1;
}

.pop {
  position: absolute;
  right: 17px;
  bottom: 0.2px;
}
.telecharger {
  position: absolute;
  right: 38px;
  bottom: 10.6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
}
</style>