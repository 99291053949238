<template>
<div>
        <navbar></navbar>
      
      <router-view></router-view>

</div>
</template>

<script>
import navbar from  "./components/layout/Header";
export default {
  name: "App",
  components: {
    navbar
  }
}
</script>

<style scoped>

</style>
