<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto my-5">
                    <h1 class="space-heading text-center">Mentions légales</h1>
                </div>
            </div>
            <div class="row mx-md-5 px-md-5">
                <p class="text-legal">
                    <strong>Editeur</strong><br>
                    Conformément à l’article 43-10 de la loi du 1er septembre 1986 relative à la liberté de communication 
                    (modifiée par la loi L200-719 du 1er août 2000), vous êtes informé que vous vous trouvez actuellement 
                    sur le site web expertfromages.fr (ci-après le « Site »), édité par le Centre National Interprofessionnel 
                    de l’Économie Laitière (CNIEL), association à but non lucratif immatriculée au répertoire SIREN sous le 
                    numéro 300 817 954.<br><br>
                    Le Site expertfromage.fr a été réalisé à l’initiative du CNIEL afin d’animer le programme Formations Cheese 
                    EMF promu dans le cadre de la campagne « l’Europe a bout goût », financée avec l’aide de l’Union Européenne.<br><br>
                    Directeur de la Publication : Madame Caroline LE POULTIER<br>
                    Adresse postale du CNIEL :<br>
                    42 rue de Châteaudun<br>
                    75314 Paris Cedex 09<br>
                    Tél : 01.49.70.71.71<br>
                    Fax : 01.42.80.63.52 / 01.42.80.63.57<br><br>
                    <strong>Réalisation du Site</strong><br><br>
                    
                    Conception, création graphique et développement :<br><br>
                    La société New Venise, SAS<br><br>
                    37 à 41 rue Fernand Pelloutier<br><br>
                    92773 Boulogne-Billancourt Cedex<br><br>
                    hbokobza@venise.com<br><br>
                    Tel : +33 (0)1 70 08 70 08<br><br>
                    Crédits photographies et illustrations<br><br>
                    La présentation et tous les éléments du Site (notamment graphismes, photos, logos, textes, sons, 
                    animations...) sont protégés par la législation sur le droit d'auteur et la propriété intellectuelle, 
                    dont le CNIEL est propriétaire exclusif. La reproduction des documents du Site est autorisée à des 
                    fins exclusives d'information pour un usage personnel et privé. Toute représentation, toute reproduction, 
                    toute adaptation, toute traduction ou modification et toute utilisation de copie réalisée à d'autres fins 
                    est expressément interdite sans l’accord préalable et écrit du CNIEL, et seraient susceptibles d’en 
                    constituer la contrefaçon, sanctionnée par les articles L.122-4, L. 335-2 et suivants du Code de la 
                    propriété intellectuelle. <br><br>
                    Les photos utilisées sont fournies par Shutterstock©, Imagebank Cniel©, Tasteatlas©.<br><br>
                    <strong>Hébergeur</strong><br><br>
                    HBA SAS<br><br>
                    situé au 2 rue Kellermann – 59100 Roubaix – France
                </p>
            </div>
        </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
        <Footer />
    </div>
</template>

<script>
import Footer from "../components/layout/Footer";
export default {
  name: "LegalNotice",
  data() {
    return {
    }
  },
  components: {
      Footer
  },
  methods: {
  }
}
</script>

<style scoped>
.text-legal{
    text-align: justify;
    margin-bottom: 5rem;
    padding-right: 2rem;
    padding-left: 2rem;
}
</style>