<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto my-5">
                    <h1 class="space-heading text-center">Bienvenue dans mon fromage & moi !</h1>
                    <h3 class="title-signin text-center">Connectez-vous pour participer au programme</h3>
                </div>
            </div>
            <div class="row class-top">
                <div class="col-md-6 px-md-5 mt-2 mx-auto form-group">
                    <input type="text" name="" class="form-control" id="email"
                    v-model="login_form.email"
                    placeholder="Email">
                    <div class="label" for="email" :class="{changeplace:login_form.email}">Email</div>
                </div>
                <div class="col-md-6 px-md-5 mt-2 mx-auto form-group class-mobile">
                    <input type="password" name="" class="form-control" id="password"
                    v-model="login_form.password" 
                    placeholder="Mot de passe">
                    <div class="label" for="password" :class="{changeplace:login_form.password}">Mot de passe</div>
                    <router-link :to="{name:'ForgotPassword'}" class="text-mdp">Mot de passe oublié ?</router-link>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-8 mx-auto">
                    <p class="classError text-danger text-center">
                        {{error ? error:null}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 mx-auto d-flex justify-content-center">
                    <button class="btn-form" value="Valider"
                    :class="{changecolorbtn:login_form.email && login_form.password}"
                    :disabled="!login_form.email || !login_form.password"
                    @click="submit">
                        <span v-if="isLoading">Valider</span
                        ><moon-loader
                        :loading="!isLoading"
                        color="white"
                        size="30px"
                        align="center"
                        ></moon-loader>
                    </button>
                </div>
            </div>
        </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
        <div class="mt-5 pt-5">
          <p class="d-flex justify-content-center text-buttom-connect">
            Pas encore de compte ? <a href="/signup" class="text-seconnect">S’inscrire</a>
          </p>
        </div>
        <!-- Toast copy -->
    <div class="toast" :class="{'show' : IsVerified}">
      <div class="toast-body">
        Votre compte a été verifié !
      </div>
    </div>
<!-- End -->
    </div>
</template>

<script>
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import {signIn} from "@/services/appApi";
import {drift} from "../../native"
export default {
  name: "SignInPage",
  data() {
    return {
        error:null,
        isLoading: true,
        IsVerified: false,
        login_form: {
            email: null,
            password: null
        },
        validation_form: {
            email: null,
            password: null
        },
    }
  },
  mounted() {
    if(this.$route.query.verified  ==='true'){
      this.IsVerified= true;
      setTimeout(() =>  this.IsVerified = false, 3000);
    }
      this.drift();
      this.ifLogin();
  },
  components: {
    MoonLoader,
  },
  methods: {
    drift(){
        drift();
    },

    ifLogin(){
        this.$store.getters.getAccessTocken ? this.$router.push({name: 'espaceco'}) : null;
    },
    reset_validation() {
      this.validation_form = {
        email: null,
        password: null
      };
    },
    submit() {
        this.isLoading = false;
        this.reset_validation();
        signIn(this.login_form)
            .then(response => {
            if(response.data){
                this.error=response.data.error
                this.isLoading=false
            }
            if(response.data.access_token){
                this.login_form.email = "";
                this.login_form.password = "";
                this.$store.dispatch('set_user',response.data.user)
                this.$store.dispatch('set_accessTocken',response.data.access_token)
                const link = this.$router.resolve({name: 'espaceco'});
                window.location.replace(link.href);
            }
            })
            .catch(response => {
                this.isLoading = true;
                this.validation_form = response.data;
            })
            .finally(() => {
                this.isLoading = true;
            });
    },

  }
}
</script>

<style scoped>
  .changeplace{
    position: absolute;
    top: -1.6em;
    left: 4em;
    transition: all 0.4s;
    opacity: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #333333;
  }
    @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .changeplace {
    left: 1em;
  }
}
.class-top{
    margin-top: 10rem;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 768px) {
    .class-top{
    margin-top: 0;
    margin-bottom: 0;
  }
  }
    .text-buttom-connect{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  .text-seconnect{
    color: #882A3E;
    text-decoration: none;
    padding-left: 5px;
  }
  @media only screen and (max-width: 668px) {
  /* For mobile phones: */
  .text-seconnect{
    color: #ffffff;
    text-decoration: none;
    padding-left: 5px;
  }
}
  .text-mdp{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #000000;
  }
  .toast{
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    width: auto;
  }
  @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .class-mobile{
    margin-top: 2rem !important;
  }
}
</style>