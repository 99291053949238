<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto my-5">
                    <h1 class="space-heading text-center">Conditions Générales d’Utilisation</h1>
                </div>
            </div>
            <div class="row mx-md-5 px-md-5">
                <p class="text-legal">
                    Le Site et ses contenus sont réservés à l’usage désireux d’animer le programme Formations futures 
                    professionnels, EMF Cheese Culture (ci-après le « Programme »), promu par EMF dans le cadre de la 
                    campagne « Mon fromage et moi », financée avec l’aide de l’Union Européenne.<br>
                    À travers la campagne Mon Fromage & Moi crée en 2019, le CNIEL fait rayonner la diversité fromages 
                    français à base de lait de vache et les savoir-faire liés à toute la chaine de création.<br><br>

                    <span class="text-inscri">Inscription et accès</span><br><br>
                    Pour bénéficier des formations, les formateurs doivent s'inscrire au programme en renseignant le 
                    formulaire prévu à cet effet. Toutes les informations demandées sont nécessaires et obligatoires 
                    afin que l’accès au Programme soit conforme et actif. Les utilisateurs garantissent au CNIEL que les 
                    informations communiquées dans le formulaire d’inscription sont exactes, véridiques, complètes et à 
                    jour. Toutes informations erronées par manquement ou volontairement, relèvent de la responsabilité des 
                    utilisateurs.<br><br>
                    Un identifiant et un mot de passe, personnels et confidentiels, seront transmis à chaque utilisateur 
                    autorisé afin de lui permettre d’accéder aux formations. Ils devront être conservés et utilisés de 
                    manière à en préserver la confidentialité. L’utilisateur devra informer immédiatement le CNIEL en cas 
                    d’utilisation non autorisée de son compte ou toute autre violation de sécurité.<br><br>
                    L’accès aux Formations est réservé aux utilisateurs inscrits. L’accès au Site est possible depuis 
                    n’importe quel ordinateur connecté à Internet. L’utilisateur reconnaît avoir vérifié que la configuration
                    informatique utilisée ne contient aucun virus et qu’elle est en bon état de fonctionnement.<br><br>
                    Propriété intellectuelle<br><br>
                    La structure générale, ainsi que les logiciels, textes, images animées ou fixes, sons, savoir-faire, 
                    dessins, graphismes (…) et tout autre élément composant le Site constituent, ensemble, une œuvre protégée 
                    par les lois françaises et internationales en vigueur sur le droit d’auteur et la propriété intellectuelle 
                    dont le CNIEL est le propriétaire exclusif. <br><br>
                    L’accès au Site et aux formations n’emporte en aucun cas cession, licence ou transfert d’un quelconque 
                    droit de propriété intellectuelle au profit des utilisateurs sur les modules de formations ou tout autre 
                    élément du Site. Le CNIEL accorde aux utilisateurs inscrits le droit, à titre personnel et non cessible, 
                    d’accéder aux formations et d’utiliser les supports, pour les seuls besoins de l’animation du Programme 
                    en lien avec les formations.<br><br>
                    Les utilisateurs inscrits s’engagent à ne pas utiliser les supports de formations ou tout autre élément 
                    du Site dont le CNIEL est propriétaire en dehors de ce cadre, que ce soit à titre gratuit ou à titre 
                    commercial.<br><br>

                    Toute reproduction, représentation, modification, adaptation ou traduction, totale ou partielle de ce 
                    Site ou de ses contenus, par quelque procédé que ce soit, sans l’autorisation expresse du CNIEL, est 
                    interdite et constituerait une contrefaçon sanctionnée par les articles L.122-4, L 335-2 et suivants 
                    du Code de la propriété intellectuelle.<br><br>
                    Les marques, ainsi que les logos figurant sur le Site, celles et ceux des organisations et des partenaires, 
                    sont des marques (semi-figuratives ou non) déposées. Toute reproduction totale ou partielle de ces marques 
                    ainsi que toute représentation totale ou partielle de ces marques et/ou logos, effectuées à partir des 
                    éléments du Site sans l’autorisation expresse du CNIEL ou de ces dernières sont donc prohibées, au sens 
                    de l’article L 713-2 du Code de la propriété intellectuelle.<br><br>
                    Les liens hypertextes mis en place dans le cadre du Site en direction d’autres ressources présentes sur le 
                    réseau Internet et notamment vers ses partenaires ont fait l’objet d’une autorisation préalable. Toutefois, 
                    les sites extérieurs au CNIEL ayant un lien hypertexte ou autre avec le Site ne sont pas sous le contrôle 
                    de ce dernier et le CNIEL décline, par conséquent, toute responsabilité quant à leur contenu. L’utilisateur 
                    est seul responsable de leur utilisation.<br><br>

                    <span class="text-inscri">Mise en garde</span><br><br>
                    Pour la réalisation du Site, New Venise a mis en place un cryptage pour sécuriser la transmission de 
                    données sur internet (Cryptage SSL 128 bits).<br><br>
                    Toutefois, l’utilisateur déclare être informé du défaut de fiabilité du réseau internet, tout 
                    spécialement en termes de sécurité relative à la transmission des données, à la continuité dans 
                    l’accès au Site, aux performances en termes de volume et de rapidité de transmission des données.<br><br>
                    Notamment, le CNIEL met tout en œuvre pour offrir aux utilisateurs des informations et/ou des outils 
                    disponibles et vérifiés, mais ne saurait être tenu pour responsable des erreurs, d’une absence de 
                    disponibilité des informations et/ou de la présence de virus sur son Site.<br><br>

                    <span class="text-inscri">Base de données - hébergement</span><br><br>
                    L’utilisateur est informé que la Base de données, composée des informations recueillies sur le Site, 
                    est hébergée en France chez HBA par New Venise.<br><br>
                    
                    <span class="text-inscri">Évolution et mise à disposition du Site</span><br><br>
                    Le CNIEL se réserve le droit de faire évoluer le Site.<br><br>

                    <span class="text-inscri">Limitation de responsabilité</span><br><br>
                    L’ensemble des informations accessibles via le Site sont fournies en l’état.<br>
                    Le CNIEL ne donne aucune garantie, explicite ou implicite, et n’assume aucune responsabilité relative à 
                    l’usage, la copie, la transmission et à toute utilisation des informations, de quelque nature qu’elles 
                    soient, contenues sur le Site.<br>
                    L’utilisateur est seul responsable de l’utilisation de telles informations.<br>
                    Le CNIEL ne pourra être responsable pour quelque dommage que ce soit tant direct qu’indirect, 
                    résultant d’une information contenue sur ce Site.<br>
                    L’utilisateur s’engage à ne transmettre sur ce Site aucune information pouvant entraîner une 
                    responsabilité civile ou pénale et s’engage à ce titre à ne pas divulguer via ce Site des informations 
                    illégales, contraires à l’ordre public, aux bonnes mœurs ou diffamatoires.<br>

                    <span class="text-inscri">Loi applicable - attribution de juridiction</span><br><br>
                    Les présente CGU sont soumises à la loi française. En cas de litige, les tribunaux de Paris seront compétents.
                </p>
            </div>
        </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
        <Footer />
    </div>
</template>

<script>
import Footer from "../components/layout/Footer";
export default {
  name: "LegalNotice",
  data() {
    return {
    }
  },
  components: {
      Footer
  },
  methods: {
  }
}
</script>

<style scoped>
.text-legal{
    text-align: justify;
    margin-bottom: 5rem;
    padding-right: 2rem;
    padding-left: 2rem;
}
.text-inscri{
    text-decoration: underline;
}
</style>