<template>
  <div class="container">
      <div class="row">
          <div class="col-md-3">
            <h6 class="mt-3 mb-5 subtitle">La petite histoire du fromage</h6>
            <div class="pt-5">
                <router-link :to="{ name: 'page1' }" class="link">
                    Risotto verde et cheddar irlandais
                </router-link>
                <router-link :to="{ name: 'page2' }" class="link">
                    Volaille et Danish blue 
                </router-link>
                <router-link :to="{ name: 'page3' }" class="link">
                    Cantal brûlé datte et clémentines corse
                </router-link>
                <router-link :to="{ name: 'page4' }" class="link">
                    Plateau de fromage
                </router-link>
            </div>
          </div>
          <div class="col-md-9 my-5">
              <router-view></router-view>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    mounted(){
        this.ifUserauth()
    },
    methods:{
    ifUserauth() {
      if (!this.$store.getters.getAccessTocken) {
        this.$router.push({ name: "signin" });
      }
    },
}
}
</script>

<style>
p{
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;
}
h1{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 44px;
line-height: 66px;
/* identical to box height */


color: #000000;
}
.link{
    margin-top: 20px;
    color: #010101;
    display: flex;
}
.subtitle{
font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 21px;
display: flex;
align-items: center;
color: #882A3E;
}
.router-link-active{
font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 21px;
text-decoration-line: underline;
color: #000000;
}
hr{
    width: 30%;
    background-color: #000000;
    padding: 1px;
    opacity: 1;
}
</style>