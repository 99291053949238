<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto class-top">
                    <h1 class="mdp-change text-center">Votre mot de passe a bien été changé.<br>
                        Connectez-vous avec votre nouveau mot de passe </h1>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12 d-flex justify-content-center mt-3">
                    <router-link :to="{name:'signin'}">
                        <button class="btn-valide" value="Valider">
                        <span>Se connecter</span>
                    </button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
    </div>
</template>

<script>
export default {
  name: "PasswordChange",
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.btn-valide{
    width: 210px;
    height: 50px;
    background: #333333;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    border: none;
}
.class-top{
    margin-top: 12rem;
}
.mdp-change{
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    align-items: center;
    text-align: center;
    color: #333333;
}
</style>