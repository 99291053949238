<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <h6 class="mt-3 mb-5 subtitle">La petite histoire du fromage</h6>
      </div>
      <div class="col-md-9 mt-5">
        <h1>La carte des fromages</h1>
        <hr />
        <p>
          Un mapping du goût des fromages, pour découvrir les subtilités de
          chacun d’entre eux et trouver des fromages qui vous conviennent. Un
          bon outil pédagogique pour bien ancrer les connaissances de vos élèves
          sur les produits.
        </p>
      </div>
    </div>
    <div class="row mt-3 mb-5">
      <div class="col-md-8 mx-auto position-relative">
        <iframe
          src="https://appli.venise.com/milk/mapping/"
          allow="fullscreen"
          :class="{ minimazed: !pleinEcran, maximized: pleinEcran }"
          :scrolling="scroll"
        ></iframe>
        <div
          class="fullscreen position-absolute"
          :class="{ fullscreenposition: pleinEcran }"
        >
          <div class="col-2 text-end position-relative" v-if="!pleinEcran">
            <img
              src="/img/svg/pein-ecran-expand.svg"
              class="cursor-pointer"
              @click="pleinEcran = !pleinEcran"
              @mouseover="hover = true"
              @mouseleave="hover = false"
            />
            <svg
              class="pop"
              width="128"
              height="39"
              viewBox="0 0 128 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-show="hover"
            >
              <path
                d="M116.842 9.40419V9.61331L116.991 9.76016L126.689 19.3264L116.97 28.9705L116.822 29.1171V29.3254V32.1916C116.82 33.6919 116.215 35.1316 115.137 36.1946C114.059 37.2578 112.596 37.8574 111.069 37.8599C111.069 37.8599 111.069 37.8599 111.069 37.8599L6.65379 37.8205C5.12645 37.8181 3.66372 37.2185 2.58578 36.1552C1.50817 35.0923 0.902921 33.6528 0.900391 32.1525V6.53719C0.900389 5.03526 1.50457 3.59332 2.58254 2.52833C3.66064 1.4632 5.12461 0.86237 6.65341 0.859863H111.089C112.618 0.86237 114.082 1.4632 115.16 2.52833C116.238 3.59332 116.842 5.03526 116.842 6.53719V9.40419Z"
                fill="white"
                stroke="black"
              />
            </svg>
            <span class="text-uppercase telecharger" v-show="hover"
              >Agrandir</span
            >
          </div>
          <div class="col-2 text-end" v-else>
            <img
              src="/img/svg/pein-ecran.svg"
              class="cursor-pointer"
              @click="pleinEcran = !pleinEcran"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false,
      pleinEcran: false,
    };
  },
  mounted() {
    this.ifUserauth();
  },
  methods: {
    ifUserauth() {
      if (!this.$store.getters.getAccessTocken) {
        this.$router.push({ name: "signin" });
      }
    },
  },

  computed: {
    scroll() {
      return this.pleinEcran ? "yes" : "no";
    },
  },
};
</script>

<style>
.minimazed {
  width: 100%;
  min-height: 75vh;
  height: 75vh;
  overflow: hidden;
}
.maximized {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100vh !important;
  height: 100vh !important;
  -webkit-transform: scale(1);
  -webkit-transform-origin: 0 0;
  border: 0 none;
  z-index: 9;
}

.fullscreen {
  top: 3px;
  right: 15px;
  z-index: 9;
  cursor: pointer;
}
.fullscreenposition {
  top: -250px;
  right: -300px;
  z-index: 10;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
}
p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}
h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 66px;
  /* identical to box height */

  color: #000000;
}
.pop {
  position: absolute;
  right: 10px;
  bottom: 0px;
}
.link {
  margin-top: 20px;
  color: #010101;
  display: flex;
}
.subtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #882a3e;
}
.router-link-active {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #000000;
}
hr {
  width: 30%;
  background-color: #000000;
  padding: 1px;
  opacity: 1;
}
.telecharger {
  position: absolute;
  right: 45px;
  bottom: 10.6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
}
</style>