<template>
  <div>
    <Videos :video="video"></Videos>
  </div>
</template>

<script>
import Videos from "./Video.vue"
export default {
  components:{
    Videos
  },
  data(){
    return{
      video:{
        title :"Plateau de fromage ",
        description : "François Robin, un des meilleurs ouvriers de France fromager, vous aide à réaliser un vrai beau plateau de fromage pour les grandes occasions. Quoi de mieux qu’un professionnel pour conseiller de futurs professionnels ?",
        src : "plateau_de_fromage.mp4"
      }
    }
  },
}
</script>

<style>

</style>