<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto mt-5">
                    <h1 class="space-heading text-center mb-4">Bienvenue sur notre plateforme de formation <br>
                        “Mon fromage & moi” !</h1>
                    <h3 class="title-formateur text-center">
                        <strong>Pré-inscrivez vos formateurs</strong> : Ils recevront une invitation en avant-première,<br> 
                        et seront averti dès que la plateforme de formation sera disponible !
                    </h3>
                </div>
            </div>
            <div class="container classwidth">
                <div class="row pt-5" v-for="(row,index) in invitation_form.formers" :key="index">
                    <p class="col-md-12 px-5 text-formateur">Formateur n° {{index+1}}</p>
                    <div class="col-md-6 px-5 mx-auto form-group">
                        <input type="text" name="" class="form-control" id="nom"
                        v-model="row.last_name"
                        placeholder="Nom"
                       :class="{'is-invalid' :  validation_form[`formers.${index}.last_name`]}" >
                        <div class="invalid-feedback">
                          {{ getFirstError(validation_form[`formers.${index}.last_name`])}}
                        </div>
                        <div class="label" for="nom" :class="{ changeplace: validation_form[`formers.${index}.last_name`]}">Nom</div>
                    </div>
                    <div class="col-md-6 px-5 mx-auto form-group">
                        <input type="text" name="" class="form-control" id="prenom"
                        v-model="row.first_name"
                        placeholder="Prénom"
                        :class="{'is-invalid' :  validation_form[`formers.${index}.first_name`]}" >
                        <div class="invalid-feedback">
                          {{ getFirstError(validation_form[`formers.${index}.first_name`])}}
                        </div>
                        <div class="label" for="prenom" :class="{ changeplace: validation_form[`formers.${index}.first_name`]}">Prénom</div>
                    </div>
                    <div class="col-12 px-5 mt-4 mx-auto form-group">
                        <input type="email" name="" class="form-control" id="email" placeholder="Email"
                        v-model="row.email"
                        :class="{'is-invalid' : validation_form[`formers.${index}.email`]}" >
                        <div class="invalid-feedback">
                          {{ getFirstError(validation_form[`formers.${index}.email`])}}
                        </div>
                        <div class="label" for="email" :class="{ changeplace: validation_form[`formers.${index}.email`]}">Email</div>
                    </div>
                </div>
                <button class="btn-formateur mt-5" value="Valider"
                @click="addRow">
                    <svg class="mx-2" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7773 8.27148H17.9141V11.3477H10.7773V19.4336H7.50781V11.3477H0.371094V8.27148H7.50781V0.800781H10.7773V8.27148Z" fill="white"/>
                    </svg>
                    Ajouter un formateur
                </button>
              <div class="row mt-5 mx-auto ps-3">
                <div class="col-md-6 ps-4">
                  <label class="form-check-label" for="flexCheckChecked1">
                    <input
                        class="form-check-input me-2"
                        type="checkbox"
                        value="1"
                        id="flexCheckChecked1"
                        @change="handleCheckboxClicked"
                        :checked="invitation_form.flexCheckChecked1"
                    />
                    J'ai lu et accepté la
                    <a @click="handleCheckboxClicked" class="theme-color anchor">
                    politique de gestion des données personnelles</a></label
                  >
                </div>
                <div class="col-md-6">
                  <label class="form-check-label" for="flexCheckChecked2">
                    <input
                        class="form-check-input me-2"
                        type="checkbox"
                        value="1"
                        id="flexCheckChecked2"
                        @change="handleCheckboxClickedTwo"
                        :checked="invitation_form.flexCheckChecked2"
                    />
                    Avoir lu et accepté les
                    <a @click="handleCheckboxClickedTwo" class="theme-color anchor">
                      conditions d’utilisation
                    </a>
                    de la plateforme</label
                  >
                </div>
              </div>
            </div>
        </div>

      <div class="row my-5">
        <div class="col d-flex align-items-center justify-content-center">
          <vue-recaptcha
            sitekey="6LfgapwdAAAAAKojMQi_dFvgKOg8_CbztUJepykb"
            :loadRecaptchaScript="true"
            ref="recaptcha"
            type="invisible"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            >
          </vue-recaptcha>
          <p class="classError text-danger small" v-if="validation_form.recaptcha">
            <ul>
              <li v-for="error in validation_form.recaptcha" :key="error">
                {{error}}
              </li>
            </ul>
          </p>
          <button
            type="submit"
            class="btn-valide ml-1 ms-3"
            value="Valider"
            @click="submit"
            :class="{
              changecolorbtnvalid:
                invitation_form.formers.length > 0 &&
                invitation_form.flexCheckChecked1 &&
                invitation_form.flexCheckChecked2
            }"
            :disabled="
              !invitation_form.formers.length > 0 ||
              !invitation_form.flexCheckChecked1 ||
              !invitation_form.flexCheckChecked2
            "
          >
            <span v-if="load">Valider</span
            ><moon-loader
              :loading="!load"
              color="white"
              size="30px"
              align="center"
            ></moon-loader>
          </button>         
        </div>
      </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
        <Policypopup @dialog-closed="eventHandler"></Policypopup>
        <CGUpopup @dialog-closed="eventHandlerTwo"></CGUpopup>
    </div>
</template>

<script>
import {invitationApi} from "@/services/appApi";
import Policypopup from "@/components/Policypopup.vue";
import CGUpopup from "@/components/CGUpopup.vue";
import VueRecaptcha from 'vue-recaptcha';
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
export default {
  name: "InvitationFormateur",
  data() {
    return {
        load: true,
        invitation_form: {
        formers:[
            {
            first_name: '',
            last_name: '',
            email: '',
            },            
            {
            first_name: '',
            last_name: '',
            email: '',
            },            
            {
            first_name: '',
            last_name: '',
            email: '',
            }
        ],
        flexCheckChecked1: '',
        flexCheckChecked2: '',
        recaptcha: "",
        host: window.location.origin
        },
        validation_form: {
            formers: null,
            flexCheckChecked1 : null,
            flexCheckChecked2: null
        },
    }
  },
  mounted() {
      this.drift();
  },
  components: {
    MoonLoader,
    Policypopup,
    CGUpopup,
    'vue-recaptcha' : VueRecaptcha,
  },
  methods: {
      addRow: function() {
            this.invitation_form.formers.push(            
            {
            first_name: '',
            last_name: '',
            email: '',
            }
            );
        },
      eventHandler(){
      this.invitation_form.flexCheckChecked1 = true;
    },
    eventHandlerTwo(){
      this.invitation_form.flexCheckChecked2 = true;
    },
     handleCheckboxClicked() {
      if(!this.invitation_form.flexCheckChecked1){
        window.$('#CGUModal').modal('show')
        this.invitation_form.flexCheckChecked1 = false;
      }
    },
    handleCheckboxClickedTwo() {
      if(!this.invitation_form.flexCheckChecked2){
        window.$('#CGUModalTwo').modal('show')
        this.invitation_form.flexCheckChecked2 = false;
      }
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.signup_form.recaptcha = recaptchaToken
      this.validateCaptcha = true
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    reset_validation() {
      this.validation_form = {
        formers: null,
        flexCheckChecked1: null,
        flexCheckChecked2: null,
        recaptcha: null
      };
    },
    validBeforeSend() {
      let arr = [...this.invitation_form.formers];
      return arr.filter(item => item.last_name !== '' || item.first_name  !== '' || item.email !== '');
    },
    getFirstError(item) {
        if (item) {
          return item[0];
        }
        return null;
    },
    submit() {
        this.load = false;
      this.reset_validation();
      let arr = this.validBeforeSend();
      if (arr.length !== 0) {
        this.invitation_form.formers = arr;
      }
      else {
        this.invitation_form.formers = [
          {
            first_name: '',
            last_name: '',
            email: '',
          },
        ];
      }

      invitationApi(this.invitation_form)
          .then(() => {
            this.invitation_form = {
              formers:[
                {
                  first_name: '',
                  last_name: '',
                  email: '',
                },
                {
                  first_name: '',
                  last_name: '',
                  email: '',
                },
                {
                  first_name: '',
                  last_name: '',
                  email: '',
                }
              ],
              flexCheckChecked1: '',
              flexCheckChecked2: '',
              recaptcha: ""
            };
            this.load = true;
            this.$router.push({ name: 'landingpageWithoutToken' });
          })
          .catch(({response}) => {
            this.validation_form = response.data.errors;
            this.load = true;
          });
    },
  },
}
</script>

<style scoped>
  .changeplace{
    position: absolute;
    top: -2em;
    left: 3em;
    color: #212529;
    transition: all 0.4s;
  }
  .classError{
      color: red;
  }
  .title-formateur{
    font-family: Gotham;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #333333;
}
.text-formateur{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    color: #333333;
}
.btn-formateur{
    background: #882A3E;
    width: 206px;
    height: 49px;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-decoration-line: underline;
    color: #F6F4ED;
    border: none;
    margin-left: 2rem;
}
.btn-valide{
    background: #F2F2F2;
    border-radius: 5px;
    width: 206px;
    height: 49px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: black;
    border: none;
    }
.changecolorbtnvalid{
    background: #882A3E;
    color: #F2F2F2;
    border: none;
}
.classwidth{
    max-width: 1100px;
}
</style>