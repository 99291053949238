<template>
  <div class="container course-warper" v-if="courses">
    <div class="row mt-3">
      <div class="col-12">
        <h4 class="title">{{ courses[0].module.title }}</h4>
        <h2 v-if="courses">{{ index + 1 }}/{{ courses.length }}</h2>
      </div>
    </div>
    <div class="row course-header">
      <div class="col-8 offset-2 text-center pt-lg-5">
        <h3>{{ courses ? courses[index].title : "" }}</h3>
      </div>
      <div class="col-1 text-end pleinEcran" v-if="!pleinEcran">
        <img
          src="/img/svg/pein-ecran-expand.svg"
          class="cursor-pointer"
          @click="FullScreenEcran"
        />
      </div>
      <div class="col-2 text-end pleinEcran" v-else v-show="pleinEcran">
        <img
          src="/img/svg/pein-ecran.svg"
          class="cursor-pointer"
          @click="FullScreenEcran"
        />
      </div>
    </div>
    <div class="row course-body">
      <div
        class="col-1 d-flex align-items-center justify-content-end"
        @click="decremant()"
      >
        <svg
          class="pointer-cursor"
          width="36"
          height="66"
          viewBox="0 0 36 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34 64L4 33L34 2"
            stroke="black"
            stroke-width="5"
            @click="index--"
            v-if="index > 0"
          />
        </svg>
      </div>
      <div class="col-10">
        <div
          class="content container-fluid"
          v-html="courses[index].content"
        ></div>
      </div>
      <div
        class="col-1 d-flex align-items-center justify-content-end"
        @click="incremant()"
      >
        <svg
          class="pointer-cursor"
          width="36"
          height="66"
          viewBox="0 0 36 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L32 33L2 64"
            stroke="black"
            stroke-width="5"
            v-if="index < courses.length - 1"
          />
        </svg>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <input
        v-if="index === courses.length - 1"
        type="submit"
        class="btnQuizz"
        value="Passer au quizz"
        @click="userCompleteModule"
      />
    </div>
    <div class="course-footer">
      <p v-if="index < courses.length - 1">
        <a class="cursor-pointer-quizz" @click="userCompleteModule">
          Passer au quizz tout de suite</a
        >
      </p>
      <p class="number-lenght" v-if="index === courses.length - 1">
        {{ index + 1 }}/{{ courses.length }}
      </p>
      <div class="footer-img" v-if="index < courses.length - 1">
        <img class="class-img-btm" src="/img/img-buttom.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCoursesByModule } from "@/services/appApi";
export default {
  data() {
    return {
      courses: null,
      module: null,
      index: 0,
      pleinEcran: false,
    };
  },
  mounted() {
    this.ifUserauth();
    this.getCoursesByModuleMe();
    this.fullscreen();
  },
  methods: {
    ifUserauth() {
      if (!this.$store.getters.getAccessTocken) {
        this.$router.push({ name: "signin" });
      }
    },
    incremant() {
      if (this.index > this.courses.length - 1) {
        this.userCompleteModule();
      }
      if (this.index < this.courses.length - 1) {
        this.index++;
      }
    },
    decremant() {
      if (this.index > 0) this.index--;
    },
    getCoursesByModuleMe() {
      getCoursesByModule({
        params: {
          moduleId: this.$route.params.id,
        },
      })
        .then((resp) => {
          this.courses = resp.data;
        })
        .catch(({ response }) => {
          this.validation_form = response.data.errors;
        });
    },

    userCompleteModule() {
      if (!this.$route.params.revoir) {
        this.$router.push({
          name: "quizzquestion",
          params: { id: this.$route.params.id },
        });
      }
    },
    fullscreen() {
      if (document.fullscreenElement) this.pleinEcran = true;
      else this.pleinEcran = false;
    },

    FullScreenEcran() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        this.pleinEcran = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          this.pleinEcran = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.index {
  position: absolute;
  bottom: 0;
  right: 0;
}
.content {
  overflow: auto;
  height: 65vh;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-top: 2rem;
}
.footer-img {
  position: relative;
  width: 100%;
}
.footer-img img {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 668px) {
  /* For mobile phones: */
  .content {
    overflow: auto;
    height: 65vh;
    padding-right: 0;
    padding-left: 0;
    padding-top: 2rem;
  }
}
.content::-webkit-scrollbar {
  width: 12px;
}

.content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.position {
  position: absolute;
  bottom: 0;
}
.pointer-cursor {
  cursor: pointer;
  text-decoration: underline;
}
h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #882a3e;
}
@media only screen and (max-width: 468px) {
  /* For mobile phones: */
  .cursor-pointer-quizz {
    font-size: 12px;
  }
  /* .content {
    box-sizing: border-box;
    width: 250px;
    margin: 0 auto;
  } */
  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #882a3e;
  }
}
.text-title-1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 44px;
  text-align: center;
  color: #000000;
}
h3 {
  font-weight: bold;
  font-size: 44px;
  text-align: center;
  color: #000000;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  h3 {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #000000;
  }
}
@media only screen and (max-width: 468px) {
  /* For mobile phones: */
  .pleinEcran {
    display: none;
  }
  h3 {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #000000;
  }
}
p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}
a {
  font-family: Poppins;
  font-size: 16px;
  text-decoration-line: underline;
  color: #000000;
}
h2 {
  margin-bottom: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.cursor-pointer {
  cursor: pointer;
}
.course-warper {
  display: flex !important;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.course-warper .row {
  flex: 1;
}

.course-header {
  flex: 1;
  align-items: center;
  margin: 0;
}
.course-body {
  flex-grow: 1;
  margin: 0;
}
.course-footer {
  position: absolute;
  top: 100vh;
  flex: 1;
  margin-top: 25vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 2rem;
}

.course-footer .pagination h2 {
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: right;
  color: #000;
}
.btnQuizz {
  width: 200px;
  height: 70px;
  background: #000000;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #f6f4ed;
  border: none;
  margin-top: 1rem;
  z-index: 1;
}
.number-lenght {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  text-align: right;
  color: #000000;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .number-lenght {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 60px;
    text-align: right;
    color: #000000;
    margin: 0;
  }
}
.cursor-pointer-quizz {
  cursor: pointer;
}
@media only screen and (min-width: 468px) {
  /* For mobile phones: */
  .cursor-pointer-quizz {
    cursor: pointer;
    font-size: 18px;
  }
}
td p {
  display: flex;
  flex-direction: column;
}
</style>
