<template>
  <div>
    <Videos :video="video"></Videos>
  </div>
</template>

<script>
import Videos from "./Video.vue"
export default {
  components:{
    Videos
  },
  data(){
    return{
      video:{
        title :"Risotto verde et cheddar irlandais",
        description : "Pierre Sang et tout son savoir-faire culinaire vous donne rendez-vous pour 3 idées recettes exceptionnelles à base de 3 fromages européens. Des recettes originales parfaites pour inspirer vos élèves et mettre en oeuvres leurs compétences.",
        src : "risotto_verde_et_cheddar_irlandais.mp4"
      }
    }
  },
}
</script>

<style scoped>
.video{
  position: relative;
}
.download{
    position: absolute;
    bottom: 44px;
    right: 12px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    opacity: 0.9;
    transition: opacity 0.4s;
}


</style>