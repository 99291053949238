<template>
  <div class="d-flex flex-column">
    <h2>OOOPS!! Page Not Found</h2>
    <h1 class="mt-5">404</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  font-size: 40vh;
  text-align: center;
}
h2 {
  font-size: 10vh;
  text-align: center;
  color: #882a3e;
}
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 10vh;
    text-align: center;
  }
  h2 {
    font-size: 10vh;
    text-align: center;
    color: #882a3e;
  }
}
</style>