<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto my-5">
                    <h1 class="space-heading text-center">Bienvenue dans mon fromage et moi !</h1>
                </div>
            </div>
            <div class="row my-5 py-5 ">
                <div class="col-md-4 px-5 mt-2 mx-auto form-group">
                    <input type="text" name="" class="form-control" id="name"
                    v-model="invitation_form.first_name" 
                    :error-messages="validation_form.first_name">
                    <div class="label" for="text" :class="{changeplace:invitation_form.first_name}">Prenom</div>
                    <p class="classError">
                        {{validation_form.first_name ? validation_form.first_name[0]:null}}
                    </p>
                </div>
                <div class="col-md-4 px-5 mt-2 mx-auto form-group">
                    <input type="text" name="" class="form-control" id="name"
                    v-model="invitation_form.last_name" 
                    :error-messages="validation_form.last_name">
                    <div class="label" for="text" :class="{changeplace:invitation_form.last_name}">Nom</div>
                    <p class="classError">
                        {{validation_form.last_name ? validation_form.last_name[0]:null}}
                    </p>
                </div>
                <div class="col-md-4 px-5 mt-2 mx-auto form-group">
                    <input type="email" name="" class="form-control" id="email"
                    v-model="invitation_form.email" 
                    :error-messages="validation_form.email">
                    <div class="label" for="email" :class="{changeplace:invitation_form.email}">email</div>
                    <p class="classError">
                        {{validation_form.email ? validation_form.email[0]:null}}
                    </p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-2 mx-auto">
                    <input type="submit" class="btn-form" value="Valider"
                    :class="{changecolorbtn:!invitation_form.first_name || !invitation_form.last_name || !invitation_form.email}"
                    :disabled="!invitation_form.first_name || !invitation_form.last_name || !invitation_form.email"
                    @click="submit" v-if="loading">
                    <img src="" alt="" v-else>
                </div>
            </div>
        </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
    </div>
</template>

<script>
import {invitationApi} from "@/services/appApi";
export default {
  name: "Invitation",
  data() {
    return {
        invitation_form: {
            first_name: '',
            last_name: '',
            email: '',
        },
        validation_form: {
            first_name: null,
            last_name: null,
            email: null,
        },
    }
  },
  mounted() {
      this.drift();
  },
  components: {

  },
  methods: {
      drift(){
        const form = document.querySelectorAll(".form-control");
        const label = document.querySelectorAll(".label");
        for (let i = 0; i < form.length; i++) {
        form[i].addEventListener("click", () => {
            label[i].classList.add("change-place");
        });
        label[i].addEventListener("click", () => {
            label[i].classList.add("change-place");
            form[i].focus();
        });
        form[i].addEventListener("blur", () => {
            if(form[i].value.length== 0){
            label[i].classList.remove("change-place");
            }
        });
    }},
    reset_validation() {
      this.validation_form = {
            first_name: null,
            last_name: null,
            email: null,
      };
    },
    submit() {
        this.reset_validation();
        invitationApi(this.invitation_form)
            .then(() => {
                this.invitation_form.first_name = "";
                this.invitation_form.last_name = "";
                this.invitation_form.email = "";
            })
            .catch(({response}) => {
              this.validation_form = response.data.errors;
            })
            .finally(() => {
            });
    },
  },
}
</script>

<style scoped>
  .changeplace{
    position: absolute;
    top: -2em;
    left: 3em;
    color: #212529;
    transition: all 0.4s;
  }
  .classError{
      color: red;
  }
</style>