import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueToastr from '@deveodk/vue-toastr'
import store from './store'
import VueContentPlaceholders from 'vue-content-placeholders'
import Config from './config/index'

Vue.prototype.$stage_url = Config.stage_url;
Vue.prototype.$prod_url = Config.prod_url;

Vue.prototype.$full_url = window.location.origin;

Vue.use(VueContentPlaceholders)
Vue.use(VueToastr, {
    defaultPosition: 'toast-top-left',
    defaultType: 'info',
    defaultTimeout: 1000
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
