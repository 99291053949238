import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../pages/LandingPage";
import SignUpPage from "../pages/auth/SignUp";
import SignIn from "../pages/auth/SignIn";
import MyProfile from "../pages/auth/MyProfile";
import EspaceCo from "../pages/EspaceCo";
import Questionnaire from "../pages/Questionnaire";
import Course from "../pages/courses/teacher/Course";
import Answers from "../pages/courses/teacher/Answers";
import QuizzQuestion from "../pages/courses/teacher/QuizzQuestion";
import QuizzReponse from "../pages/courses/teacher/QuizzReponse";
import Invitation from "../pages/auth/Invitation";
import InvitationFormateur from "../pages/auth/InvitationFormateur";
import ConfirmationEmail from "../pages/auth/ConfirmationEmail";
import PageNotFound from "../pages/404";
import config from "../config";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import PasswordChange from "../pages/auth/PasswordChange";
import LegalNotice from "../pages/LegalNotice";
import CGU from "../pages/CGU";
import Policy from "../pages/Policy";
import Reward from "../pages/courses/Reward";
import FromageCard from "../pages/courses/FromageCard";
import Page1 from "../pages/courses/reward/page1";
import Page2 from "../pages/courses/reward/page2";
import Page3 from "../pages/courses/reward/page3";
import Page4 from "../pages/courses/reward/page4";

Vue.use(VueRouter);

let routes = null;

// if (window.location.origin.includes('8080')) {
routes = [
  {
    path: "*",
    name: "404",
    component: PageNotFound,
  },
  {
    path: "/",
    name: "landingpageWithoutToken",
    component: LandingPage,
  },
  {
    path: "/:token/home",
    name: "landingpage",
    component: LandingPage,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUpPage,
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
  },
  {
    path: "/myprofile",
    name: "myprofile",
    component: MyProfile,
  },
  {
    path: "/espaceco",
    name: "espaceco",
    component: EspaceCo,
  },
  {
    path: "/questionnaire/:id?",
    name: "questionnaire",
    component: Questionnaire,
  },
  {
    path: "/quizzquestion/:id?",
    name: "quizzquestion",
    component: QuizzQuestion,
  },
  {
    path: "/quizzreponse/:id",
    name: "quizzreponse",
    component: QuizzReponse,
  },
  {
    path: "/invitation",
    name: "invitation",
    component: Invitation,
  },
  {
    path: "/invitation-formateur",
    name: "InvitationFormateur",
    component: InvitationFormateur,
  },
  {
    path: "/module/:id/courses/:revoir?",
    name: "course",
    component: Course,
  },
  {
    path: "/module/:id/answers/",
    name: "answers",
    component: Answers,
  },
  {
    path: "/confirmation-email",
    name: "ConfirmationEmail",
    component: ConfirmationEmail,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/password-change",
    name: "PasswordChange",
    component: PasswordChange,
  },
  {
    path: "/legal-notice",
    name: "LegalNotice",
    component: LegalNotice,
  },
  {
    path: "/cgu",
    name: "CGU",
    component: CGU,
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/fromage_card",
    name: "FromageCard",
    component: FromageCard,
  },
  {
    path: "/reward",
    name: "Reward",
    component: Reward,
    redirect: { name: "page1" },
    children: [
      {
        path: "/risotto-verde-et-cheddar-irlandais",
        name: "page1",
        component: Page1,
      },
      {
        path: "/volaille-et-danish-blue",
        name: "page2",
        component: Page2,
      },
      {
        path: "/cantal-brûlé-datte-et-clémentines-corse",
        name: "page3",
        component: Page3,
      },
      {
        path: "/plateau-de-fromage",
        name: "page4",
        component: Page4,
      },
    ],
  },

  //   ]
  // // }
  // // else {
  //   routes = [
  {
    path: "*",
    name: "404",
    component: PageNotFound,
  },
  {
    path: "/",
    name: "landingpageWithoutToken",
    component: LandingPage,
  },
  {
    path: "/invitation-formateur",
    name: "InvitationFormateur",
    component: InvitationFormateur,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUpPage,
  },
];
// }

const router = new VueRouter({
  mode: "history",
  base: config.base_name,
  routes,
});

export default router;
