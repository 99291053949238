import axios from "axios";
import Config from '../config';

export const signIn = (data) => {
    return axios.post(`${Config.app_url}/auth/login`,data);
};
export const signUp = (data) => {
    return axios.post(`${Config.app_url}/auth/register`,data);
};
export const updateMyProfil = (token, data) => {
    return axios.post(`${Config.app_url}/auth/my-profile/${token}`,data);
};
export const signUpWithoutToken = (data) => {
    return axios.post(`${Config.app_url}/registerwithouttoken`,data);
};
export const signUpUsingToken = (token, data) => {
    return axios.post(`${Config.app_url}/register-using-token/${token}`, data);
};
export const invitationApi = (data) => {
    return axios.post(`${Config.app_url}/create`,data);
};
export const getByToken = (token) => {
    return axios.get(`${Config.app_url}/user/${token}/getContact`);
};
export const getCurrentUser = (data) => {
    return axios.post(`${Config.app_url}/auth/me`,data);
};
export const regiterUserToModule = (data) => {
    return axios.post(`${Config.app_url}/regiterUserToModule`,data);
};
export const userPaticipateToModule = (data) => {
    return axios.post(`${Config.app_url}/userPaticipateToModule`,data);
};
export const userCompleteModule = (data) => {
    return axios.post(`${Config.app_url}/userCompleteModule`,data);
};
export const getModule = (data) => {
    return axios.get(`${Config.app_url}/getModule`,data);
};
export const getPdf = (id) => {
    return axios.get(`${Config.app_url}/getPdf/${id}`);
};
export const getCoursesByModule = (data) => {
    return axios.get(`${Config.app_url}/getCoursesByModule`,data);
};
export const getQuestionsByModule = (id) => {
    return axios.get(`${Config.app_url}/getQuestionsByModule/${id}`);
};
export const addUserAnswers = (data) => {
    return axios.post(`${Config.app_url}/addUserAnswers`,data);
};
export const getUserAnswers = (data) => {
    return axios.post(`${Config.app_url}/getUserAnswers`,data);
};
// export const getQuestionsByModule = (data) => {
//     return axios.get(`${Config.app_url}/getCoursesByModule`,data);
// };
export const makeOpinion = (data) => {
    return axios.post(`${Config.app_url}/makeOpinion`,data);
};
export const recevoirCertif = (data) => {
    return axios.post(`${Config.app_url}/completeAllModule`,data);
};



export const ForgotPassword = (data) => {
    return axios.post(`${Config.app_url}/auth/password/forget` , data);
};
export const ResetPassword = (data) => {
    return axios.post(`${Config.app_url}/auth/password/reset` , data);
};