<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto my-5">
                    <h1 class="space-heading text-center">Bienvenue dans mon fromage & moi !</h1>
                    <h3 class="title-signin text-center">Veuillez saisir votre email pour changer votre mot de passe</h3>
                </div>
            </div>
            <div class="row class-top">
                <div class="col-md-6 px-md-5 mt-2 mx-auto form-group">
                    <input type="text" name="" class="form-control" id="email"
                    v-model="forgot_form.email"
                    placeholder="Email">
                    <div class="label" for="email" :class="{changeplaceLabel:forgot_form.email}">Email</div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-8 mx-auto">
                    <p class="classError text-danger text-center">
                        {{error ? error:null}}
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                 <div class="col-md-12 d-flex justify-content-center">
                    <button class="btn-valide" value="Valider"
                    :class="{changecolorbtnvalid:forgot_form.email}"
                    :disabled="!forgot_form.email"
                    @click="submit" id="myBtn">
                        <span v-if="isLoading">Valider</span
                        ><moon-loader
                        :loading="!isLoading"
                        color="white"
                        size="30px"
                        align="center"
                        ></moon-loader>
                    </button>
                </div>
            </div>
        </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
        <div class="class-mtop">
          <p class="d-flex justify-content-center text-buttom-connect">
            Pas encore de compte ? <a href="/signup" class="text-seconnect">S’inscrire</a>
          </p>
        </div>
<!-- Toast copy -->
    <div class="toast" :class="{'show' : IsSend}">
      <div class="toast-body">
        Un email a été envoyé pour<br> reset votre mot de passe
      </div>
    </div>
<!-- End -->
    </div>
</template>

<script>
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import {ForgotPassword} from "@/services/appApi";
import {drift} from "../../native"
export default {
  name: "ForgotPassword",
  data() {
    return {
      error:null,
        isLoading: true,
        IsSend: false,
        forgot_form: {
        email: '',
        },
        validation_form:{
        email: null,
      }
    }
  },
  components: {
      MoonLoader,
  },
  mounted() {
    this.drift();
  },
  methods: {
    drift(){
        drift();
    },
    reset_validation() {
      this.validation_form = {
        email: null,
      }
    },
    submit() {
      this.isLoading = false;
      this.reset_validation();
      ForgotPassword(this.forgot_form)
      .then((response) => {
        if(response.data.send){
          this.forgot_form = {
            email: ''
          };
          this.IsSend = true;
          setTimeout(() =>  this.IsSend = false, 3000);
        }else{
          this.error=response.data.message;
          
        }
      })
      .catch((response) => {
        this.isLoading = true;
        this.validation_form = response.data.message;
      })
      .finally(() => {
        this.isLoading = true;
      });
    }
  },
}
</script>

<style scoped>
 .changeplaceLabel{
    position: absolute;
    top: -1.6em;
    left: 4em;
    transition: all 0.4s;
    opacity: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #333333;
  }
  @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .changeplaceLabel {
    left: 1em;
  }
}
.btn-valide{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #828282;
    width: 206px;
    height: 49px;
    background: #F2F2F2;
    border-radius: 5px;
    border: none;
}
.changecolorbtnvalid{
    background: #333333;
    color: #F2F2F2;
}
.class-top{
    margin-top: 8rem;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .class-top{
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
}
  .class-mtop{
    margin-top: 10rem;
  }
  @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .class-mtop{
    margin-top: 5rem;
  }
}
  .toast{
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    width: auto;
  }
  .text-buttom-connect{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  .text-seconnect{
    color: #882A3E;
    text-decoration: none;
    padding-left: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
</style>