<template>
  <div class="container">
    <!-- Popup -->
    <div class="modal fade" id="CGUModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content align-self-center">
          <div class="row container">
            <div class="col-12 d-flex justify-content-end">
              <svg
                width="120"
                height="140"
                viewBox="0 0 120 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.417 28.2963L0 27.3537C0.158621 22.6411 1.72575 18.0787 4.5059 14.2356C7.28605 10.3925 11.1562 7.43892 15.6334 5.74321C20.1107 4.04751 24.997 3.68481 29.6827 4.70031C34.3684 5.71581 38.6461 8.06454 41.9822 11.4536C45.3183 14.8426 47.5651 19.122 48.4424 23.7577C49.3196 28.3934 48.7885 33.1803 46.9153 37.5214C45.042 41.8624 41.9094 45.5653 37.9085 48.1682C33.9075 50.7711 29.2153 52.1588 24.417 52.1581C23.1549 52.1638 21.8944 52.0698 20.6476 51.877L24.417 28.2963Z"
                  fill="#882A3E"
                />
                <path
                  d="M95.8342 23.9975L71.6191 20.6903C72.3749 15.1775 75.0656 10.097 79.2266 6.32573C83.3877 2.55445 88.758 0.329039 94.4106 0.0336981C100.063 -0.261643 105.643 1.39161 110.188 4.70804C114.733 8.02446 117.956 12.796 119.303 18.1989C120.649 23.6019 120.034 29.2972 117.563 34.3018C115.092 39.3064 110.92 43.3063 105.768 45.611C100.616 47.9156 94.8072 48.3804 89.3437 46.9251C83.8802 45.4698 79.1052 42.1858 75.8429 37.64L95.8342 23.9975Z"
                  fill="#882A3E"
                />
                <path
                  d="M64.8866 100.313L103.405 88.5723C104.632 92.3692 105.257 96.3292 105.256 100.313C105.256 110.839 101.001 120.933 93.4273 128.376C85.8534 135.819 75.581 140 64.8698 140C54.1587 140 43.8862 135.819 36.3123 128.376C28.7384 120.933 24.4834 110.839 24.4834 100.313C24.4877 98.0964 24.679 95.884 25.0555 93.6985L64.8866 100.313Z"
                  fill="#882A3E"
                />
              </svg>
            </div>
            <div class="col-12">
              <p class="text-felicit">Félicitation {{ user.last_name }},</p>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-11">
              <p class="p-envoye">un mail a été envoyé</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End popup -->

    <div class="content py-2">
      <div class="row">
        <div class="col-md-10">
          <h4 class="fw-bold">
            Bonjour {{ user.first_name }} {{ user.last_name }},
          </h4>
          <p class="ellipse-text">
            Voici {{ modules.length }} modules de cours sur la thématique du
            fromage se terminant par une série de question pour tester vos
            acquis.<br />
            Vous êtes libre de les suivre dans l’ordre que vous souhaitez.
          </p>
        </div>
      </div>
      <div class="row mt-3 py-3 ellipse" v-if="moduleLoad">
        <div
          v-for="n in 2"
          :key="n"
          class="
            col-md-3
            my-5
            mx-auto
            d-flex
            flex-column
            align-items-center
            form-group
          "
        >
          <content-placeholders class="w-100">
            <content-placeholders-heading />
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
        </div>
      </div>
      <div class="row mt-3 py-3 ellipse d-flex justify-content-center" v-else>
        <div
          class="col-md-3 my-5 d-flex flex-column align-items-center form-group"
          v-for="module in modules"
          :key="module.id"
        >
          <h5 class="">{{ module.name }}</h5>
          <div v-if="!module.pivot.is_completed" class="my-2">
            <svg
              width="167"
              height="167"
              viewBox="0 0 167 167"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="83.5"
                cy="83.5"
                r="72.5"
                stroke="#353E4E"
                stroke-width="21"
              />
            </svg>
          </div>
          <div v-else class="my-2">
            <svg
              width="167"
              height="167"
              viewBox="0 0 167 167"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                v-if="!pleinEcran"
                cx="83.5"
                cy="83.5"
                r="72.5"
                :stroke="module.pivot.status"
                stroke-width="21"
              />
              <circle
                v-else
                cx="83.5"
                cy="83.5"
                r="72.5"
                stroke="#353E4E"
                stroke-width="21"
              />
            </svg>
          </div>
          <input
            v-if="!module.pivot.is_completed"
            type="button"
            class="btn btn-form"
            @click="userPaticipateToModule(module.id)"
            :value="
              module.pivot.is_participated
                ? 'Commencer le cours'
                : 'Commencer le cours'
            "
          />
          <input
            v-if="module.pivot.is_completed"
            type="button"
            class="btn btn-form"
            @click="goToModule(module.id)"
            value="Revoir le cours"
          />
          <input
            v-if="
              module.pivot.is_completed &&
              module.id == 1 &&
              module.pivot.recomponse == 1
            "
            type="button"
            class="btn btn-form mt-3 second-btn"
            @click="voirVideos()"
            value="Découvrez les vidéos"
          />
          <input
            v-if="
              module.pivot.is_completed &&
              module.id == 1 &&
              module.pivot.recomponse == 1
            "
            type="button"
            class="btn btn-form mt-3 second-btn changecolorbtn text-white"
            @click="getAnswers(1)"
            value="Voir les réponses"
          />
          <input
            v-if="
              module.pivot.is_completed &&
              module.id == 2 &&
              module.pivot.recomponse == 1
            "
            type="button"
            class="btn btn-form mt-3 second-btn"
            @click="voirِCard()"
            value="Découvrez la carte des fromages"
          />
          <input
            v-if="
              module.pivot.is_completed &&
              module.id == 2 &&
              module.pivot.recomponse == 1
            "
            type="button"
            class="btn btn-form mt-3 second-btn changecolorbtn text-white"
            @click="getAnswers(2)"
            value="Voir les réponses"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-md-2 pb-3 mx-auto d-flex flex-column align-items-center"
        >
          <input
            type="submit"
            class="ellipse-btn changecolorbtn"
            value="Mode présentation"
            @click="FullScreen"
          />
        </div>
      </div>
      <div class="row mb-3"></div>
    </div>
  </div>
</template>

<script>
import { toggleDocument } from "@/native";
import {
  userPaticipateToModule,
  getModule,
  recevoirCertif,
} from "@/services/appApi";
export default {
  name: "EspaceCo",
  data() {
    return {
      user: {},
      modules: [],
      pleinEcran: false,
      moduleLoad: true,
      showMessage: false,
      isLoading: false,
    };
  },
  components: {},
  mounted() {
    this.ifUserauth();
    this.getModule();
    toggleDocument();
    if (document.fullscreenElement) this.pleinEcran = true;
    else this.pleinEcran = false;
  },
  computed: {
    isCanRecevoirCertif() {
      let isNotCompeted = this.modules.find((el) => el.pivot.is_completed == 0);
      return isNotCompeted == undefined ? true : false;
    },
  },
  methods: {
    voirVideos() {
      this.$router.push({ name: "page1" });
    },
    voirِCard() {
      this.$router.push({ name: "FromageCard" });
    },
    getAnswers(moduleId){
      this.$router.push({ name: "answers" , params: { id: moduleId } });
    },
    ifUserauth() {
      if (!this.$store.getters.getAccessTocken) {
        this.$router.push({ name: "signin" });
      }
    },
    FullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        this.pleinEcran = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          this.pleinEcran = false;
        }
      }
      if (document.fullscreenElement);
      else this.pleinEcran = false;
    },
    recevoirCertif() {
      this.isLoading = true;
      if (this.isCanRecevoirCertif) {
        recevoirCertif({
          userId: this.user.id,
        })
          .then(() => {
            this.isLoading = false;
            window.$("#CGUModal").modal("show");
          })
          .catch(() => {})
          .finally(() => {});
      }
    },

    userPaticipateToModule(moduleId) {
      userPaticipateToModule({
        userId: this.user.id,
        moduleId: moduleId,
      })
        .then(() => {
          this.$router.push({ name: `course`, params: { id: moduleId } });
        })
        .catch(() => {});
    },
    goToModule(moduleId) {
      this.$router.push({ name: `course`, params: { id: moduleId } });
    },
    getModule() {
      this.user = this.$store.getters.getUser;
      getModule({
        params: {
          userId: this.user.id,
        },
      })
        .then((response) => {
          this.modules = response.data.modules;
        })
        .catch(() => {})
        .finally(() => {
          this.moduleLoad = false;
        });
    },
  },
};
</script>

<style scoped>
.second-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 14px !important;
  align-items: center;
  text-align: center;

  color: #000000;
}
.certif-message {
  position: absolute;
  top: 60px;
  height: 50px;
}
.ellipse h5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  text-shadow: 0.3px 0 #000;
  color: #000000;
}
.ellipse input {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  text-shadow: 0.3px 0 #000;
  color: #000;
  border: none;
  border-radius: 0;
  width: 200px;
}

.ellipse-btn {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-shadow: 0.3px 0 #000;
  color: #000000;
  border: none;
  border-radius: 5px;
  width: 206px;
  height: 49px;
  margin: 5px;
  margin-top: 3rem;
}
.ellipse-btn-top {
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  border: none;
  background: #333333;
  border-radius: 5px;
  width: 210px;
  height: 50px;
  margin: 5px;
}
.ellipse-btn-top:disabled {
  color: #9e9e9e;
  background: #f2f2f2;
}
.ellipse-text {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}
.changecolorbtn {
  width: 206px;
  height: 49px;
  background: #333333;
  border-radius: 5px;
  color: #fff;
}

/* style popup */
.modal-dialog {
  height: 90vh;
  width: auto;
  max-width: 1100px;
  display: flex;
  border: 0;
}
.modal-content {
  height: 597px;
  padding-top: 4rem;
  border: none;
  border-radius: 0;
}
.text-felicit {
  font-family: Gotham;
  font-weight: bold;
  font-size: 26px;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
}
.p-envoye {
  font-family: Gotham;
  font-weight: 450;
  font-size: 18px;
}
/* style popup */

.changecolorRecevoirBtn {
  cursor: not-allowed;
  background: #f2f2f2;
  border-radius: 5px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  color: #9e9e9e;
}
</style>
