<template>
  <footer class="footer-dark">
    <div class="container">
      <div class="row">
        <div class="col-md-3 item d-flex flex-column px-5">
          <router-link
            class="text-decoration-none text-white py-1"
            :to="{ name: 'signin' }"
            >Connexion</router-link
          >
          <router-link
            class="text-decoration-none text-white py-1"
            :to="{ name: 'signup' }"
            >Inscription</router-link
          >
        </div>
        <div class="col-md-4 item d-flex flex-column px-5">
          <router-link
            class="text-decoration-none text-white py-1"
            :to="{ name: 'LegalNotice' }"
            >Mentions légales</router-link
          >
          <router-link
            class="text-decoration-none text-white py-1"
            :to="{ name: 'CGU' }"
            >Conditions Générales d’Utilisation</router-link
          >
          <router-link
            class="text-decoration-none text-white py-1"
            :to="{ name: 'Policy' }"
            >Politique de gestion des données personnelles</router-link
          >
        </div>
        <div class="col-md-5 d-flex justify-content-end px-5">
          <a href="https://www.facebook.com/LesFromages/" target="_blank">
            <img src="/img/facebook.png" alt="" srcset="" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
</style>