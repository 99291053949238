<template>
  <div>
    <Videos :video="video"></Videos>
  </div>
</template>

<script>
import Videos from "./Video.vue"
export default {
  components:{
    Videos
  },
  data(){
    return{
      video:{
        title :"Cantal brûlé datte et clémentines corse",
        description : "Pierre Sang et tout son savoir-faire culinaire vous donne rendez-vous pour 3 idées recettes exceptionnelles à base de 3 fromages européens. Des recettes originales parfaites pour inspirer vos élèves et mettre en oeuvres leurs compétences.",
        src : "cantal_brûlé_datte_et_clémentines_corse.mp4"
      }
    }
  },
}
</script>

<style>

</style>