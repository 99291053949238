import { render, staticRenderFns } from "./ForgotPassword.vue?vue&type=template&id=9275e69c&scoped=true&"
import script from "./ForgotPassword.vue?vue&type=script&lang=js&"
export * from "./ForgotPassword.vue?vue&type=script&lang=js&"
import style0 from "./ForgotPassword.vue?vue&type=style&index=0&id=9275e69c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9275e69c",
  null
  
)

export default component.exports