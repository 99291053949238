<template>
    <div class="hole-space">
        <div class="container px-4 content">
            <div class="row">
                <div class="col-md-8 mx-auto my-5">
                    <h1 class="space-heading text-center">Politique de gestion des données personnelles</h1>
                </div>
            </div>
            <div class="row mx-md-5 px-md-5">
                <p class="text-legal">
                    <span class="mx-4">Publiée <strong>le 15/12/2021,</strong></span><br><br>
                    Le CNIEL collecte des informations vous concernant et/ou des données personnelles 
                    (ci-après désignées les « Données Personnelles ») via son site internet <a class="text-link" href="https://www.expertfromage.fr">expertfromage.fr</a>
                    (ci-après « le Site »)<br><br>
                    <strong>RECUEIL DES DONNEES PERSONNELLES</strong><br>
                    <ul>
                        <li>Les informations recueillies par votre utilisation du site web <a class="text-link" href="https://www.expertfromage.fr">expertfromage.fr</a> sont 
                            strictement destinées au CNIEL et à son sous-traitant New Venise dans le cadre de la campagne 
                            « l’Europe a bon goût » en vue de la réalisation des traitements suivants :<strong>La création 
                            d’un compte utilisateur</strong> : Les Données Personnelles que vous communiquez au CNIEL 
                            pour la création de votre compte utilisateur sur le Site afin de participer au programme 
                            « l’Europe a bon goût », ne sont utilisées qu’à des fins de création et de gestion de 
                            votre compte utilisateur, et conservées pour la durée du programme. Vous pouvez à tout 
                            moment demander la suppression de votre compte par mail à contact@ veniseactivation. </li>
                    </ul><br>
                    <strong>CONFIDENTIALITE</strong><br>
                    Le CNIEL et New Venise mettent en œuvre toutes les mesures techniques et organisationnelles nécessaires 
                    pour garantir la sécurité et la confidentialité des Données Personnelles collectées et traitées, et 
                    notamment empêcher qu’elles soient déformées, endommagées ou communiquées à des Tiers non autorisés, 
                    en assurant un niveau de sécurité adapté aux risques liés au traitement et à la nature des données à 
                    protéger, eu égard au niveau technologique et au coût de mise en œuvre.<br><br>
                    En vertu de la législation applicable à la protection des données à caractère personnel, vous disposez
                    d’un droit d’accès, de modification, de rectification et de suppression des données qui vous concernent. 
                    Vos demandes doivent être adressées par courriel à l’adresse suivante : contact@ veniseactivation.<br><br>
                    Vous disposez également de la possibilité d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL).<br><br>
                    <strong>POLITIQUE DE GESTION DES COOKIES</strong><br><br>
                    <strong>Cookies</strong><br>
                    Les cookies sont de petits fichiers de données envoyées par un site internet et qui sont stockés sur votre navigateur internet lorsque vous visitez un site internet. Ils permettent de sauvegarder temporairement des informations anonymes concernant les visites et visiteurs du site. A chaque visite d’un internaute sur le site, les données de sa précédente visite sont récupérées.<br><br>
                    Certains cookies sont strictement nécessaires à l’utilisation du site.<br><br>
                    D’autres cookies ou technologies similaires, déposés par le CNIEL ou des tiers permettent de personnaliser et d’optimiser l’affichage de certains contenus, de conserver vos préférences, de mesurer la performance du site, de sécuriser l’accès au site, de partager du contenu, et de détecter des anomalies.<br><br>
                    <strong>Utilisation des cookies par le CNIEL</strong><br>
                    1. Les cookies fonctionnels<br>
                    Les cookies fonctionnels sont nécessaires au fonctionnement du site internet.<br><br>
                    Les cookies fonctionnels sont indispensables au fonctionnement des sites du CNIEL et à la navigation des utilisateurs sur ces derniers. Ils permettent aux personnes d'utiliser les principales fonctionnalités des sites et de sécuriser leur connexion. Ils peuvent leur permettre également d'accéder directement à des espaces qui leur sont réservés, grâce à des identifiants ou des données qu’elles ont éventuellement antérieurement confiées au CNIEL.<br><br>
                    Sans ces cookies, les utilisateurs ne pourront pas utiliser normalement les sites et il est déconseillé par conséquent d’empêcher leur utilisation ou de les supprimer.<br><br>
                    Type : Stockage web local<br><br>
                    Finalité : Permet de retenir vos informations de connexion<br><br>
                    Durée : 365 jours<br><br>
                    <strong>Gestion de vos choix de cookies :</strong><br><br>
                    <strong>Par défaut, le refus est supposé et aucun traceur n'est déposé. Vous pouvez consentir au dépôt </strong>des traceurs dans la fenêtre de [Gestion des cookies] présente en bas de chaque page du site.<br><br>
                    Par ailleurs, pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos préférences en matière de cookies. Par exemple :<br><br>
                    
                </p>
                <p class="text-links">
                    Pour Internet Explorer™ : <a class="text-link" href="http://windows.microsoft.com/fr-fr/windows7/block-enable-or-allow-cookies" target="_blank">http://windows. microsoft.com /fr-fr/windows7/block-enable-or-allow-cookies</a><br>
                    Pour Safari™ : <a class="text-link" href="http://support.apple.com/kb/PH19214?viewlocale=fr_FR" target="_blank">http://support.apple.com/kb/ PH19214?viewlocale=fr_FR</a><br>
                    Pour Chrome™: <a class="text-link" href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647" target="_blank">http://support.google.com/ chrome/bin/answer.py?hl=fr &hlrm=en&answer=95647</a><br>
                    Pour Firefox™ : <a class="text-link" href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies" target="_blank">http://support. mozilla.org/fr/ kb/Activer%20 et%20d%C3%A9sactiver %20les%20cookies</a><br>
                    Pour Opera™ : <a class="text-link" href="http://help.opera.com/Windows/10.20/fr/cookies.htm" target="_blank">http://help.opera.com/ Windows/10.20/fr/ cookies.htm</a>
                </p>
            </div>
        </div>
        <div class="circle-space"></div>
        <div class="pack-space"></div>
        <Footer />
    </div>
</template>

<script>
import Footer from "../components/layout/Footer";
export default {
  name: "Policy",
  data() {
    return {
    }
  },
  components: {
      Footer
  },
  methods: {
  }
}
</script>

<style scoped>
.text-legal{
    text-align: justify;
    padding-right: 2rem;
    padding-left: 2rem;
}
.text-inscri{
    text-decoration: underline;
}
.text-links{
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: 5rem;
}
.text-link{
    text-decoration: none;
    color: black;
}
</style>