import { render, staticRenderFns } from "./InvitationFormateur.vue?vue&type=template&id=653c6c95&scoped=true&"
import script from "./InvitationFormateur.vue?vue&type=script&lang=js&"
export * from "./InvitationFormateur.vue?vue&type=script&lang=js&"
import style0 from "./InvitationFormateur.vue?vue&type=style&index=0&id=653c6c95&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653c6c95",
  null
  
)

export default component.exports