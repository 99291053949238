<template>
  <v-app>
      <Header />
    <header class="header-background">
        <img src="/img/banner.jpg" alt="">
    </header>
    <div class="container px-lg-5">
        <div class="row">
            <div class="col-md-12 mx-auto d-flex justify-content-center py-3 my-lg-5">
                <p class="article-title text-center">Bienvenue sur notre plateforme de formation !</p>
            </div>
        </div>
        <div class="row d-flex justify-content-between">
            <div class="col-lg-6 col-12">
                <img src="/img/image-8.jpg" alt="" srcset="" width="100%" height="100%">
            </div>
            <div class="col-lg-6 col-12  d-flex flex-column order-sm-1 order-md-2 pt-sm-2">
            <div class="px-md-4 mt-2 pt-3 px-3">
                <div class="article-head">Nos connaissances et notre savoir-faire<br> au service de l'apprentissage.</div>
                <p class="article mt-2">
                    Avec le soutien de l'Union Européenne, le CNIEL vous propose un tout nouvel outil d'apprentissage interactif 
                    pour former des experts dans le domaine du fromage.<br>
                    Fierté de notre patrimoine, reflet de la richesse de nos terroirs, le fromage est une base indispensable dans 
                    l'enseignement des arts culinaires. <br><br>
                    <span >
                      Il ne vous reste plus qu'à vous connecter pour découvrir la formation.
                    </span>

                </p>
                <router-link  :to="{name: 'signin'}">
                    <button class="btn-part1-top">C’est parti</button>
                </router-link>
            </div>
        </div>

        </div>
            <div class="row d-flex justify-content-between my-2">
        <div class="col-lg-6 col-12 d-flex flex-column order-sm-2 order-md-1 pt-sm-2 classone">
            <div class="px-md-4 mt-lg-2 pt-5 px-3">
                <div class="article-head">2 modules essentiels... et ludiques</div>
                <p class="article mt-3">
                    Découvrez les modules complémentaires et enrichissants en deux temps : une partie cours et une partie quizz pour valider les acquis.<br><br>
                    1 - Le module "La petite histoire du fromage" vous embarque pour un voyage aux origines du fromage qu'il s'agisse de sa fabrication, des terroirs ou des grandes dates qui ont marqué son évolution.<br>
                    2 - Le module "Les catégories" décrit les différentes familles de fromages, leurs caractéristiques et leurs différences. <br>
                </p>
                <router-link  :to="{name: 'signin'}">
                    <button class="btn-part1">C’est parti</button>
                </router-link>
            </div>
        </div>
        <div class="col-lg-6 col-12 order-sm-1 classtwo">
            <img src="/img/image-9.jpg" alt="" srcset="" width="100%" height="100%">
        </div>
    </div>
    <div class="row d-flex justify-content-between my-5">
        <div class="col-lg-6 col-12">
            <img src="/img/image-1.jpg" alt="" srcset="" width="100%" height="100%">
        </div>
        <div class="col-lg-6 col-12 d-flex flex-column order-sm-2 pt-sm-2">
            <div class="px-md-4 mt-5 px-3">
                <div class="article-head">Le Cniel en deux mots…</div>
                <p class="article mt-3">
                    L'Interprofession Laitière - Cniel a été créée en 1973 par les producteurs et les transformateurs.<br> 
                    Le Cniel remplit trois objectifs principaux<br>
                    <ul>
                        <li>Faciliter les relations entre producteurs transformateurs de la filière laitière </li>
                        <li>Faire progresser la filière </li>
                        <li>Promouvoir l'image du lait et des produits laitiers.</li>
                    </ul>
                </p>
                <router-link  :to="{name: 'signin'}">
                    <button class="btn-part1">C’est parti</button>
                </router-link>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-between">
        <img class="img-footer" src="/img/svg/imgButtom.svg" alt="">
    </div>
    <div class="d-flex justify-content-center">
        <img class="img-europebtm" src="/img/svg/logoTopBar.svg">
    </div>
    
    <div class="d-flex justify-content-center my-4">
        <div class="col-2"></div>
        <p class="text-buttom">
            Le contenu de cette campagne de promotion reﬂète uniquement la position de l’auteur et relève de 
            sa seule responsabilité. La Commission européenne et l’Agence exécutive européenne pour la recherche 
            (REA) déclinent toute responsabilité quant à toute utilisation qui pourrait être faite des informations
             qui y ﬁgurent.
        </p>
        <div class="col-2"></div>
    </div>
    </div>
      <Footer />
  </v-app>
</template>

<script>
import Footer from "../components/layout/Footer";
import { getByToken } from "@/services/appApi";

export default {
  name: "LandingPage",
  components: {
    Footer,
  },
  mounted() {
    //   this.verifyIfTokenExist();
  },
  methods: {
    verifyIfTokenExist() {
      if (this.$route.params.token) {
        getByToken(this.$route.params.token)
          .then((resp) => {
            if (resp.data.message) {
              this.$router.push({ name: "signin" });
            }
          })
          .catch(() => {})
          .finally(() => {});
      }
    },
  },
};
</script>

<style scoped>
.class-link {
  color: black;
  font-family: Gotham;
  font-size: 16px;
}
.text-buttom {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000;
}

@media only screen and (max-width: 768px) {
  .classone {
    order: 2;
  }
  .classtwo {
    order: 1;
  }
  .img-europebtm {
    width: 100%;
  }
}

.btn-part1-top {
  width: 144px;
  height: 40px;
  background: #882a3e;
  border-radius: 55px;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.btn-part1 {
  width: 144px;
  height: 40px;
  background: #882a3e;
  border-radius: 55px;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
</style>